import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { LoginVendor } from "../../redux/action/VendorLogin";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./LoginStyle";
import styled from "@emotion/styled";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { RotatingLines } from "react-loader-spinner";
import { EMAIL_REGEX } from "../../utils/constant";
import { useAuthContext } from "../../layouts/dashboard/AuthContext";
import { toast } from "react-toastify";

const LoginButton = styled(Button)({
  background: "#a98f57",
  color: "#f1f3fa",
  width: "100%",
  height: "50px",
});

const LoginCard = styled(Card)({
  borderRadius: 5,
  border: "none",
});

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { setAdmin, setVendor } = useAuthContext();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const navigate = useNavigate();

  const handleClick = async () => {
    try {
      setIsSuccess(false);

      if (!email || !password) {
        setEmailError(email ? "" : "* Email is required");
        setPasswordError(password ? "" : "* Password is required");
        return;
      }

      if (!EMAIL_REGEX.test(email)) {
        setEmailError("* Invalid Email");
        return;
      }
      if (!password.length) {
        setPasswordError("* Password is required");
        return;
      }

      const data = {
        email: email,
        password,
      };

      const vendorRes = await LoginVendor(data, setLoading);
      if (vendorRes.isSuccess) {
        setAdmin(false);
        setVendor(true);
        localStorage.setItem("isAdmin", "false");
        localStorage.setItem("isVendor", "true");
        const lastName = vendorRes?.data?.vendor?.last_name;
        if (lastName?.length > 1) {
          localStorage.setItem(
            "vendorName",
            `${vendorRes?.data?.vendor?.first_name} ${lastName}`
          );
        } else {
          localStorage.setItem(
            "vendorName",
            `${vendorRes?.data?.vendor?.first_name}`
          );
        }
        localStorage.setItem("vendorId", vendorRes.data.vendor._id);

        setIsSuccess(true);
        setSuccessMessage("Vendor Login successful!");
        navigate("/", {
          state: {
            vendor: vendorRes.data.vendor,
          },
        });
      } else {
        setIsSuccess(false);
        setErrorMessage(vendorRes?.displayMsg);
      }
    } catch (error) {
      console.error("Error during login", error?.message);
      setIsSuccess(false);
      toast.error(error?.message);
      setErrorMessage("An error occurred during login.");
    }
  };

  const handleEmailEvent = (e) => {
    setEmailError("");
    setEmail(e.target.value);
  };

  const handlePasswordEvent = (e) => {
    setPasswordError("");
    setPassword(e.target.value);
  };

  const classes = useStyles();

  return (
    <>
      <div className={classes.loginView}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight="100vh"
          width="100%"
        >
          <Container maxWidth={false} sx={{ maxWidth: "500px" }}>
            <LoginCard variant="outlined">
              <CardMedia>
                <Typography
                  variant="h4"
                  align="center"
                  color="#f1f3fa"
                  bgcolor="#a98f57"
                  padding="25px"
                >
                  Dr Jacks Coffee Vendor
                </Typography>
              </CardMedia>
              <CardContent className={classes.containView}>
                <Typography variant="h5" color="#6c757d" align="center">
                  Sign In
                </Typography>
                <Typography
                  variant="body2"
                  color="#98a6ad"
                  align="center"
                  paddingTop={1}
                  fontWeight={600}
                >
                  Enter your email address and password to <br /> access Vendor
                  panel.
                </Typography>
              </CardContent>
              <Stack spacing={3} padding={"20px"}>
                <FormControl variant="outlined">
                  <TextField
                    type="email"
                    label="Email"
                    value={email}
                    onChange={(e) => {
                      handleEmailEvent(e);
                    }}
                  />
                  <div className="invalid-msg mt-2">{emailError}</div>
                </FormControl>
                <FormControl variant="outlined">
                  <TextField
                    name="password"
                    label="Password"
                    value={password}
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => {
                      handlePasswordEvent(e);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div className="invalid-msg mt-2">{passwordError}</div>
                </FormControl>
              </Stack>
              {!isSuccess && errorMessage && (
                <div className={classes.mL}>
                  <span style={{ color: "red" }}> * {errorMessage}</span>
                </div>
              )}
              {isSuccess && successMessage && (
                <div className={classes.mL}>
                  <span style={{ color: "green" }}> * {successMessage}</span>
                </div>
              )}
              <Stack
                justifyContent="center"
                alignItems="center"
                display="flex"
                padding="20px"
              >
                <LoginButton variant="text" onClick={handleClick}>
                  {loading ? (
                    <RotatingLines
                      strokeColor="#fff"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="20"
                      visible
                    />
                  ) : (
                    "Login"
                  )}
                </LoginButton>
              </Stack>
            </LoginCard>
          </Container>
        </Box>
      </div>
    </>
  );
};

export default Login;
