import { Navigate, useRoutes } from "react-router-dom";
// layouts
import UserPage from "./pages/User/UserPage";
import Login from "./auth/Login/Login";
import OrderPage from "./pages/Orders/OrdersPage";
import ProductPage from "./pages/Products/ProductPage";
import DashboardLayout from "./layouts/dashboard/DashboardLayout";
import { getAdminToken } from "./utils/Storage";
import Dashboard from "./pages/Dashboard/Dashboard";
import ProductDetails from "./pages/Products/ProductDetails";
import OrderDetails from "./pages/Orders/OrderDetails";
import UserDetails from "./pages/User/UserDetails";
// import ReviewPage from "./pages/ReviewPage/ReviewPage";
import VendorPage from "./pages/Vendor/VendorPage";
import VendorDetails from "./pages/Vendor/VendorDetails";
import { useAuthContext } from "./layouts/dashboard/AuthContext";
import "react-toastify/dist/ReactToastify.css";
// import Category from "./pages/category/Category";
import ChangePassword from "./pages/Vendor/ChangePassword";
import VendorProfile from "./pages/VendorProfile/VendorProfile";
import TransactionPage from "./pages/Transaction/TransactionPage";

export default function Router() {
  const AdminToken = getAdminToken();

  const { isAdmin } = useAuthContext();

  const commonRoutes = [
    { path: "/", element: <Dashboard /> },
    { path: "product", element: <ProductPage /> },
    { path: "productDetails", element: <ProductDetails /> },
    { path: "orders", element: <OrderPage /> },
    { path: "orderDetails", element: <OrderDetails /> },
    { path: "transaction", element: <TransactionPage /> },
  ];

  const adminRoutes = [
    ...commonRoutes,
    { path: "users", element: <UserPage /> },
    { path: "userDetails", element: <UserDetails /> },
    { path: "vendor", element: <VendorPage /> },
    { path: "vendorDetails", element: <VendorDetails /> },
    // { path: "category", element: <Category /> },
  ];

  const vendorRoutes = [
    ...commonRoutes,
    // { path: "review", element: <ReviewPage /> },
    { path: "change-password", element: <ChangePassword /> },
    { path: "vendor-profile", element: <VendorProfile /> },
  ];
  const routes = useRoutes([
    {
      path: "/",
      element:
        AdminToken === null ? <Navigate to="/login" /> : <DashboardLayout />,
      children: isAdmin ? adminRoutes : vendorRoutes,
    },
    {
      path: "/login",
      element: AdminToken === null ? <Login /> : <Navigate to="/" />,
      index: true,
    },
    {
      path: "/",
      element: (
        <Navigate
          to="/"
          state={{
            isAdmin: true,
            isVendor: false,
          }}
        />
      ),
    },
  ]);
  return routes;
}
