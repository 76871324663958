import React from "react";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { Colors } from "../../utils/Colors";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 300,
  backgroundColor: Colors.WHITE,
  p: 2,
  borderRadius: 1,
};

const RemoveModal = ({ removeModal, handleCloseModal, handelDeleteItem, message }) => (
  <Box>
    <Modal
      open={removeModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack direction="row" alignItems="center" justifyContent="end">
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={handleCloseModal}
          >
            <CloseIcon style={{ color: "#a98f57" }} />
          </IconButton>
        </Stack>
        <Grid
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <DeleteIcon
            style={{ height: "auto", width: "70px", color: "#a98f57" }}
          />
        </Grid>
        <Stack
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            id="modal-modal-description"
            variant="h4"
            gutterBottom
            sx={{ mt: 1, color: "#495057", marginBottom: 0 }}
            style={{ textAlign: "center" }}
          >
            Are you sure?
          </Typography>
          <Typography
            sx={{
              margin: 1,
              marginLeft: 10,
              marginRight: 10,
              color: "#878a99",
              fontSize: 16,
            }}
            style={{ textAlign: "center" }}
          >
            Are you sure you want to remove this  {message}
          </Typography>
          <Stack spacing={2} direction="row" marginTop="10" display="flex">
            <Button
              variant="outlined"
              onClick={handleCloseModal}
              style={{
                color: "#a98f57",
              }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              onClick={handelDeleteItem}
              style={{
                backgroundColor: "#a98f57",
              }}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  </Box>
);

export default RemoveModal;
