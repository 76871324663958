import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Drawer, Typography, CardMedia } from "@mui/material";
import useResponsive from "../../../hooks/useResponsive";
import Scrollbar from "../../../components/scrollbar";
import NavSection from "../../../components/nav-section";
import { vendorNavConfig } from "./config";
import { useAuthContext } from "../AuthContext";

const NAV_WIDTH = 280;

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
  isAdmin: PropTypes.bool,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");
  const { isAdmin, isVendor } = useAuthContext();
  const location = useLocation();
  const vendor = location?.state?.vendor;
  const vendorName = localStorage.getItem("vendorName");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname, isAdmin, isVendor]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <CardMedia>
        <Typography
          variant="h4"
          align="center"
          color="#000"
          padding="25px"
          fontSize="large"
        >
            <Typography variant="h6" color="#000">
              {" " + (vendor?.first_name ?? vendorName) ?? ""}{" "}
              {vendor?.last_name ?? ""}
            </Typography>
        </Typography>
      </CardMedia>
      <NavSection data={vendorNavConfig} />
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
