import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import momenttz from "moment-timezone";
import { CircularProgress } from "../../components/common_Component/CommonLoader";
import { GetTransactionFailed } from "../../redux/action/GetTransactionFailed";

const DeclinedOrder = ({ search, page, onPageChange }) => {
  const dispatch = useDispatch();

  const getDeclinedOrders = useSelector(
    (state) => state?.getTransactionPaymentFailed?.getTransactionFailedList
  );

  const fetching = useSelector((state) => state?.getTransactionPaymentFailed?.fetching);

  const handleGetTransactionFailed = (pageNumber, search) => {
    dispatch(GetTransactionFailed(pageNumber + 1, search ?? ""));
  };

  useEffect(() => {
      handleGetTransactionFailed(page, search);
  }, [page, search]);

  const columns = [
    { id: "id", label: "Id", minWidth: 30 },
    { id: "userName", label: "User Name", minWidth: 80 },
    {
      id: "productCount",
      label: "No. Of Products",
      minWidth: 20,
    },
    {
      id: "date",
      label: "Date",
      minWidth: 100,
    },
    {
      id: "reason",
      label: "Reason",
      minWidth: 50,
    },
    {
      id: "Amount",
      label: "Amount",
      minWidth: 50,
    },
  ];

  const formatDateInCentralTime = (date) => {
    const timezone = momenttz.tz.guess(); // This guesses the user's local timezone
    const centralDate = moment(date).tz(timezone);
    return centralDate.format("MM/DD/YYYY, h:mm A");
  };

  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  key={column?.id}
                  align={column?.align}
                  style={{
                    minWidth: column?.minWidth,
                    backgroundColor: "#a98f57 ",
                    color: "#fff",
                  }}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fetching ? (
              <TableRow>
                <TableCell colSpan={columns?.length} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {getDeclinedOrders?.result?.length ? (
                  getDeclinedOrders?.result?.map((e, index) => {
                    return (
                      <TableRow hover tabIndex={-1}>
                        <TableCell>{e?.orderNumber}</TableCell>
                        <TableCell>
                          {(e?.userDetails?.first_name
                            ? e?.userDetails?.first_name
                            : "-") +
                            " " +
                            (e?.userDetails?.last_name
                              ? e?.userDetails?.last_name
                              : "-")}
                        </TableCell>
                        <TableCell align="center">
                          {e?.accounting?.cartAccountingList?.length}
                        </TableCell>
                        <TableCell>
                          {formatDateInCentralTime(e?.updatedAt)}
                        </TableCell>
                        <TableCell>
                          <p className="text-overflow">{e?.reason}</p>
                        </TableCell>
                        <TableCell>
                          ${Number(e?.accounting?.finalTotal).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns?.length} align="center">
                      No transaction available.
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={getDeclinedOrders?.meta?.totalCount ?? 0}
        rowsPerPage={10}
        page={page ?? 0}
        onPageChange={onPageChange}
        labelDisplayedRows={({ from, to, count }) =>
          !count ? "0-0 of 0" : `${from}-${to} of ${count}`
        }
      />
    </>
  );
};

export default DeclinedOrder;
