import * as React from "react";
import {
  Container,
  Tooltip,
  Select,
  FormControl,
  MenuItem,
  Alert,
  InputLabel,
  Modal,
  Box,
  Chip,
  Typography,
  Tabs,
  Tab,
  Button,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import ComponentTitle from "../../components/common_Component/pageTitle/ComponentTitle";
import { useStyles } from "./orderStyle";
import UserListToolbar from "../../sections/@dashboard/user/UserListToolbar";
import Iconify from "../../components/iconify";
import OrderModal from "../../components/common_Component/OrderModal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetAllOrderAction } from "../../redux/action/GetOrderAction";
import Snackbar from "@mui/material/Snackbar";
import { useAuthContext } from "../../layouts/dashboard/AuthContext";
import { useEffect } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GetExportOrderAction } from "../../redux/action/GetExportOrderAction";
import PropTypes from "prop-types";
import moment from "moment";
import CartOrder from "./CartOrder";
import PaidOrder from "./PaidOrder";
import ShippedOrder from "./ShippedOrder";
import CancelOrder from "./CancelOrder";
import DeclinedOrder from "./DeclinedOrder";
import { useState } from "react";
import { CircularProgress } from "../../components/common_Component/CommonLoader";
import Loader from "react-js-loader";
import momenttz from "moment-timezone";

const columnsVendor = [
  { id: "orderId", label: "Order Id", minWidth: 80 },
  { id: "userName", label: "User Name", minWidth: 80 },
  { id: "email", label: "Email", minWidth: 80 },
  { id: "phoneNumber", label: "Phone Number", minWidth: 80 },
  { id: "Date", label: "Date", minWidth: 80 },
  {
    id: "Product Name",
    label: "Product Name",
    minWidth: 100,
  },
  { id: "Payment Method", label: "Payment Method", minWidth: 100 },
  {
    id: "Total",
    label: "Total",
    minWidth: 50,
  },
  {
    id: "Status",
    label: "Status",
    minWidth: 90,
  },
  {
    id: "Action",
    label: "Action",
    minWidth: 50,
  },
];

// Start Tab
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>{children}</Paper>
          </Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// End Tab
export default function OrderPage() {
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [searchExport, setSearchExport] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [orderData, setOrderData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSuccessOpen, setSuccessOpen] = useState(false);
  const [isErrorOpen, setErrorOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState("All Vendor");
  const [modalExport, setModalExport] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { isAdmin } = useAuthContext();
  const [tabValue, setTabValue] = useState(0);
  const [statusFilter, setStatusFilter] = useState("");
  const [loadingExport, setLoadingExport] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPage(0);
    setSearch("");
  };

  const handleOrderGetAll = (search, pageNumber, tab) => {
    if (tabValue === 0) {
      dispatch(GetAllOrderAction(search ?? "", pageNumber + 1, tab));
    }
  };

  useEffect(() => {
    if (modalExport) {
      setLoadingExport(true);
      dispatch(
        GetExportOrderAction(
          moment(startDate).format("DD-MM-YYYY"),
          moment(endDate).format("DD-MM-YYYY"),
          searchExport,
          statusFilter
        )
      ).then(() => setLoadingExport(false));
    }
  }, [startDate, endDate, searchExport, statusFilter, modalExport, dispatch]);

  const handleExportSearchInput = (e) => {
    setSearchExport(e.target.value);
  };

  const handleOrderGetAllState = useSelector(
    (state) => state.GetAllOrder.orderGetAllData
  );
  const orderGetExport = useSelector(
    (state) => state.GetExportOrder.orderGetExportData
  );

  const fetching = useSelector((state) => state.GetAllOrder.fetching);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleOrderGetAll(search, newPage, tabValue);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSearchInput = (e) => {
    setSearch(e.target.value);
    setPage(0);
    handleOrderGetAll(e.target.value, 0, tabValue);
  };

  const handleNavigate = (data) => {
    navigate("/orderDetails", { state: data });
  };

  useEffect(() => {
    handleOrderGetAll("", page, tabValue);
  }, [page]);

  const handleSuccessClose = () => {
    setSuccessOpen(false);
    handleOrderGetAll(search, page);
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const formatDateInCentralTime = (date) => {
    const timezone = momenttz.tz.guess();
    const centralDate = moment(date).tz(timezone);
    return centralDate.format("MM/DD/YYYY, h:mm A");
  };

  const classes = useStyles();

  return (
    <>
      <Container className={classes.container}>
        <ComponentTitle pageTitle="Orders" />
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: "0px",
            }}
          >
            <UserListToolbar
              search={search}
              handleSearch={handleSearchInput}
              name="Order"
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setModalExport(true);
                }}
              >
                Order Download
              </Button>
            </div>
          </div>

          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="basic tabs example"
              >
                <Tab label="All" {...a11yProps(0)} />
                <Tab label="Cart" {...a11yProps(1)} />
                <Tab label="Cancelled" {...a11yProps(2)} />
                <Tab label="Declined" {...a11yProps(3)} />
                <Tab label="Paid" {...a11yProps(4)} />
                <Tab label="Completed" {...a11yProps(5)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
              {fetching ? (
                <CircularProgress />
              ) : (
                <>
                  {!isAdmin && (
                    <TableContainer>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columnsVendor.map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  minWidth: column.minWidth,
                                  backgroundColor: "#a98f57",
                                  color: "#fff",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "200px",
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {handleOrderGetAllState &&
                          handleOrderGetAllState?.result?.length > 0 ? (
                            handleOrderGetAllState?.result?.map(
                              (row, index) => {
                                return (
                                  <TableRow hover tabIndex={-1} key={row._id}>
                                    <TableCell>{row?.orderNumber}</TableCell>
                                    <TableCell>
                                      {row?.userDetails?.first_name +
                                        " " +
                                        row?.userDetails?.last_name}
                                    </TableCell>
                                    <TableCell>
                                      {row?.userDetails?.email}
                                    </TableCell>
                                    <TableCell>
                                      {row?.userDetails?.phone_number}
                                    </TableCell>
                                    <TableCell>
                                      {formatDateInCentralTime(row.createdAt)}
                                    </TableCell>
                                    {row?.accounting?.cartAccountingList
                                      ?.length > 0 && (
                                      <TableCell>
                                        {
                                          row.accounting.cartAccountingList[0]
                                            .productName
                                        }
                                      </TableCell>
                                    )}
                                    <TableCell>Card</TableCell>
                                    <TableCell>
                                      $
                                      {row.accounting.finalTotal
                                        .toFixed(2)
                                        .toLocaleString("en-IN")}
                                    </TableCell>
                                    <TableCell>
                                      {row?.status === "P" && (
                                        <Chip
                                          label="Pending"
                                          size="small"
                                          color="warning"
                                        />
                                      )}
                                      {row?.status === "C" && (
                                        <Chip
                                          label="Cart"
                                          size="small"
                                          color="warning"
                                        />
                                      )}
                                      {row?.status === "CL" && (
                                        <Chip
                                          label="Cancelled"
                                          size="small"
                                          color="error"
                                        />
                                      )}
                                      {row?.status === "PD" && (
                                        <Chip
                                          label="Payment Declined"
                                          size="small"
                                          color="primary"
                                        />
                                      )}
                                      {row?.status === "PF" && (
                                        <Chip
                                          label="Payment Failed"
                                          size="small"
                                          color="error"
                                        />
                                      )}
                                      {row?.status === "S" && (
                                        <Chip
                                          label="Shipped"
                                          size="small"
                                          color="secondary"
                                        />
                                      )}
                                      {row?.status === "PC" && (
                                        <Chip
                                          label="Paid"
                                          size="small"
                                          color="success"
                                        />
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <div>
                                        <>
                                          <Tooltip
                                            title="View"
                                            placement="bottom"
                                          >
                                            <Iconify
                                              icon={"eva:eye-fill"}
                                              sx={{ mr: 2 }}
                                              onClick={() => {
                                                handleNavigate(row);
                                              }}
                                            />
                                          </Tooltip>
                                        </>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )
                          ) : (
                            <TableRow>
                              <TableCell
                                colSpan={columnsVendor.length}
                                align="center"
                              >
                                No orders available.
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  <OrderModal
                    handleClose={handleClose}
                    isUser
                    data={orderData}
                    page={page}
                    isOpen={isOpen}
                  />
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={handleOrderGetAllState?.meta?.totalCount}
                    rowsPerPage={10}
                    page={page}
                    onPageChange={handleChangePage}
                    labelDisplayedRows={({ from, to, count }) =>
                      !count ? "0-0 of 0" : `${from}-${to} of ${count}`
                    }
                  />
                </>
              )}
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <CartOrder
                search={search}
                page={page}
                onPageChange={handleChangePage}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={2}>
              <CancelOrder
                search={search}
                page={page}
                onPageChange={handleChangePage}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={3}>
              <DeclinedOrder
                search={search}
                page={page}
                onPageChange={handleChangePage}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={4}>
              <PaidOrder
                search={search}
                page={page}
                onPageChange={handleChangePage}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={5}>
              <ShippedOrder
                search={search}
                page={page}
                onPageChange={handleChangePage}
              />
            </CustomTabPanel>
          </Box>
        </div>
        <div>
          <Snackbar
            open={isSuccessOpen}
            autoHideDuration={3000}
            onClose={handleSuccessClose}
          >
            <Alert severity="success" onClose={handleSuccessClose}>
              "Order updated successfully!"
            </Alert>
          </Snackbar>
          <Snackbar
            open={isErrorOpen}
            autoHideDuration={3000}
            onClose={handleErrorClose}
          >
            <Alert severity="error" onClose={handleErrorClose}>
              "Failed to update Order."
            </Alert>
          </Snackbar>
        </div>
      </Container>

      <Snackbar
        open={showAlert}
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
      >
        <Alert severity="success" onClose={() => setShowAlert(false)}>
          Review deleted successfully.
        </Alert>
      </Snackbar>

      <Modal
        open={modalExport}
        onClose={() => {
          setModalExport(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <Box
              sx={{
                maxWidth: "380px",
                my: 1,
              }}
            >
              {loadingExport ? (
                <Loader />
              ) : (
                <>
                  <UserListToolbar
                    search={searchExport}
                    handleSearch={handleExportSearchInput}
                    name="Order by Product Name"
                    width={500}
                  />
                  <div className="min-datepicker">
                    <ReactDatePicker
                      className="datepicker_input"
                      onChange={(date) => setStartDate(date)}
                      selected={startDate}
                      maxDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText={"Start Date"}
                    />
                    <div>
                      <Iconify icon={"maki:arrow"} />
                    </div>
                    <ReactDatePicker
                      className="datepicker_input"
                      selected={endDate}
                      placeholderText={"End Date"}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => setEndDate(date)}
                      startDate={startDate}
                      endDate={endDate}
                      maxDate={new Date()}
                      minDate={startDate}
                    />
                  </div>
                  <div>
                    <FormControl sx={{ width: 150, marginTop: "10px" }}>
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={statusFilter}
                        onChange={handleStatusFilterChange}
                      >
                        <MenuItem value="P">Pending</MenuItem>
                        <MenuItem value="C">Cart</MenuItem>
                        <MenuItem value="CL">Cancelled</MenuItem>
                        <MenuItem value="PD">Payment Declined</MenuItem>
                        <MenuItem value="PF">Payment Failed</MenuItem>
                        <MenuItem value="S">Shipped</MenuItem>
                        <MenuItem value="PC">Paid</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <p>{orderGetExport?.result?.length} Orders</p>

                  <div>
                    {orderGetExport?.result?.length ? (
                      <div className="buttons">
                        <div
                          className="excel-btn"
                          onClick={() => {
                            setModalExport(false);
                          }}
                        >
                          <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button"
                            table="table-to-xls"
                            filename={`${
                              moment(startDate).format("DD-MM-YYYY") +
                              " to " +
                              moment(endDate).format("DD-MM-YYYY")
                            } orders`}
                            sheet={`${
                              moment(startDate).format("DD-MM-YYYY") +
                              " to " +
                              moment(endDate).format("DD-MM-YYYY")
                            } orders`}
                            buttonText="Order Download"
                          />
                          <table id="table-to-xls" style={{ display: "none" }}>
                            <thead>
                              <tr>
                                <th scope="col">Order Id</th>
                                <th scope="col">Stripe Id</th>
                                <th scope="col">Date</th>
                                <th scope="col">Status</th>
                                <th scope="col">Product Name</th>
                                <th scope="col">Payment Method</th>
                                <th scope="col">Vendors Name</th>
                                <th scope="col">Customer Name</th>
                                <th scope="col">Customer Email</th>
                                <th scope="col">Customer Phone</th>
                                <th scope="col">Product Cost</th>
                                <th scope="col">Shipping Cost</th>
                                <th scope="col">Total</th>
                                <th scope="col">Billing Address</th>
                                <th scope="col">Shipping Address</th>
                              </tr>
                            </thead>
                            <tbody>
                              {orderGetExport?.result?.map((row, index) => {
                                return (
                                  <tr>
                                    <td>{row?._id}</td>
                                    <td>{row?.paymentId || "-"}</td>
                                    <td>
                                      {formatDateInCentralTime(row?.createdAt)}
                                    </td>
                                    <td>
                                      {row?.status === "P" && "Pending"}
                                      {row?.status === "C" && "Cart"}
                                      {row?.status === "CL" && "Cancelled"}
                                      {row?.status === "PD" &&
                                        "Payment Declined"}
                                      {row?.status === "PF" && "Payment Failed"}
                                      {row?.status === "S" && "Shipped"}
                                      {row?.status === "PC" && "Paid"}
                                    </td>
                                    <td>
                                      {row?.accounting?.cartAccountingList
                                        ?.map((item) => item.productName)
                                        .join(", ")}
                                    </td>
                                    <td>Card</td>
                                    <td>{row?.vendorNames?.join(",")}</td>
                                    <td>
                                      {row?.userDetails?.first_name +
                                        " " +
                                        row?.userDetails?.last_name}
                                    </td>
                                    <td>{row?.userDetails?.email}</td>
                                    <td>{row?.userDetails?.phone_number}</td>
                                    <td>
                                      $
                                      {(
                                        row?.accounting?.finalTotal -
                                        row?.accounting?.shippingCost
                                      ).toFixed(2)}
                                    </td>

                                    <td>
                                      $
                                      {row?.accounting?.shippingCost?.toFixed(
                                        2
                                      )}
                                    </td>
                                    <td>
                                      ${row?.accounting?.finalTotal?.toFixed(2)}
                                    </td>
                                    <td>{row?.billingAddress}</td>
                                    <td>{row?.shippingAddress}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <Button
                          color="error"
                          variant="contained"
                          onClick={() => {
                            setModalExport(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    ) : (
                      <p className="invalid-msg">
                        There are no orders received on the selected date and
                        selected status so please select another date
                      </p>
                    )}
                  </div>
                </>
              )}
            </Box>
          </div>
        </Box>
      </Modal>
    </>
  );
}
