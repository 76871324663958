import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    padding: "0",
  },
  title: {
    fontSize: "14px",
    fontFamily: "sans-serif",
    color: "#6c757d",
    marginBottom: "0px",
    marginTop: "0px",
  },
  p: {
    fontSize: "14px",
    lineHeight: "18px",
    color: "#6c757d",
    marginBottom: "0px",
    marginTop: "0px",
    whiteSpace: "pre-line",
    overflow: "hidden",
  },
  orderSummy: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgb(238, 242, 247)",
    justifyContent: "space-between",
    paddingRight: "10px",
  },
  orderText: {
    margin: "15px",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: 2,
  },
  priceText: {
    fontSize: "14px",
    fontWeight: "300",
    marginLeft: "90px",
  },
  dRow: {
    display: "flex",
    flexDirection: "row",
  },
  dCntr: {
    justifyContent: "center",
    display: "flex",
    width: "100%",
  },
  tbl1: {
    backgroundColor: "#fff",
    borderRadius: 8,
    padding: "20px",
  },
});
