import * as actions from "./index";
import { getAdminToken } from "../../utils/Storage";
import { apiInstance } from "../../httpClient";

export const GetAllProductVendorAction =
  (search, filters, pageNumber) => (dispatch) => {
    const AdminToken = getAdminToken();
    new Promise((resolve, reject) => {
      dispatch(actions.getProductVendorRequest());
      apiInstance
        .get(
          `product/vendor/all?search=${search ?? ""}&sort=${
            filters ?? ""
          }&pageNumber=${pageNumber ?? 1}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${AdminToken}`,
            },
          }
        )
        .then((result) => {
          dispatch(actions.getProductVendorSuccess(result.data.data));
          resolve(result.data);
        })
        .catch((error) => {
          dispatch(actions.getProductVendorError(error?.response?.message));
          reject(error);
        });
    });
  };
