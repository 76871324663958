import * as React from "react";
import { useState, useCallback, useEffect } from "react";
import {
  Typography,
  Box,
  TextField,
  Grid,
  Stack,
  IconButton,
  Button,
  Modal,
  InputAdornment,
  Alert,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { UpdateVendorAction } from "../../redux/action/UpdateVendorAction";
import { useDispatch, useSelector } from "react-redux";
import { GetAllVendorAction } from "../../redux/action/GetVendorAction";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AddVendor } from "../../redux/action/AddVendorAction";
import { EMAIL_REGEX } from "../../utils/constant";
import Snackbar from "@mui/material/Snackbar";
import { useStyles } from "./commonCss";
import { default_image } from "../../assets/image";
import { CircularProgress } from "./CommonLoader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: 2,
  overflowY: "scroll",
};
export default function VendorModal(props) {
  const dispatch = useDispatch();
  const imageURL = process.env.REACT_APP_IMAGE_URL;
  // build
  const handleVendorGetAll = (search, page) =>
    dispatch(GetAllVendorAction(search, page));

  const [emailError, setEmailError] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isSuccessOpen, setSuccessOpen] = useState(false);
  const [isErrorOpen, setErrorOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [commissionError, setCommissionError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [logoImage, setLogoImage] = useState("");
  const [logoError, setLogoError] = useState(false);
  const [display, setDisplay] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const vendorDetails = useSelector(
    (state) => state.setVendorDetail.setVendorData
  );
  const fetching = useSelector((state) => state.setVendorDetail.fetching);

  const [vendor, setVendor] = useState({
    id: vendorDetails?._id || "",
    firstName: vendorDetails?.first_name || "",
    lastName: vendorDetails?.last_name || "",
    email: vendorDetails?.email || "",
    website: vendorDetails?.website || "",
    status: vendorDetails?.status || "A",
    password: vendorDetails?.password || "",
    commission: vendorDetails?.commission || "",
    phoneNumber: vendorDetails?.phoneNumber || "",
    address: vendorDetails?.address || "",
    taxId: vendorDetails?.taxId || "",
    image: vendorDetails?.image || "",
    display: vendorDetails?.display,
  });

  useEffect(() => {
    if ((props.isVendor && vendorDetails) || (props.isOpen && props.data)) {
      setIsModalOpen(true);
      setVendor({
        id: vendorDetails?._id || "",
        firstName: vendorDetails?.first_name || "",
        lastName: vendorDetails?.last_name || "",
        email: vendorDetails?.email || "",
        website: vendorDetails?.website || "",
        status: vendorDetails?.status || "A",
        password: vendorDetails.password || "",
        commission: vendorDetails?.commission || "",
        phoneNumber: vendorDetails?.phone_number || "",
        address: vendorDetails?.address || "",
        taxId: vendorDetails?.taxId || "",
        image: vendorDetails?.image || "",
        display: vendorDetails?.display,
      });
      setDisplay(vendorDetails?.display);
      setLogoImage(imageURL + vendorDetails?.image);
    } else {
      setIsModalOpen(false);
      setVendor({
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        website: "",
        password: "",
        commission: "",
        phoneNumber: "",
        address: "",
        taxId: "",
        image: "",
        display: false,
      });
      setLogoImage("");
      setDisplay(false);
    }
  }, [props.isVendor, vendorDetails, props.isOpen, props.data]);

  const handelSubmit = async () => {
    if (
      !vendor?.firstName?.length &&
      !vendor?.lastName?.length &&
      !vendor?.phoneNumber?.length &&
      !vendor?.email?.length &&
      !vendor?.website?.length &&
      !vendor?.password?.length &&
      !vendor?.commission?.length &&
      !vendor?.address?.length
    ) {
      setFirstNameError("* First Name is required");
      setLastNameError("* Last Name is required");
      setEmailError("* Email is required");
      setWebsiteError("* Website is required");
      setPasswordError("* Password is required");
      setCommissionError("* Total Charge is required");
      setPhoneNumberError("* Phone Number is required");
      setAddressError("* Address is required");
      return;
    }

    if (!EMAIL_REGEX.test(vendor?.email)) {
      setEmailError("* Invalid Email");
      return;
    }
    if (!logoImage) {
      setLogoError(true);
      return;
    }
    setLoading(true);
    if (props.isVendor) {
      try {
        const formData = new FormData();
        formData.append("id", vendor?.id);
        formData.append("first_name", vendor?.firstName);
        formData.append("last_name", vendor?.lastName);
        formData.append("password", vendor?.password);
        formData.append("email", vendor?.email);
        formData.append("website", vendor?.website);
        formData.append("status", vendor?.status ?? "A");
        formData.append("commission", vendor?.commission);
        formData.append("taxId", vendor?.taxId);
        formData.append("image[]", logoImage);
        formData.append("phone_number", vendor?.phoneNumber);
        formData.append("address", vendor?.address);
        formData.append("display", display);
        const response = await UpdateVendorAction(formData);
        if (response.isSuccess) {
          setSuccessOpen(true);
          props.handleClose();
          props.handleVendorGetAll("", 0);
          setLoading(false);
        } else {
          setErrorOpen(true);
          setLoading(false);
          setErrorMsg(response.data.message);
        }
      } catch (error) {
        console.log("error: ", error);
      }
    } else {
      try {
        const formData = new FormData();
        formData.append("image[]", logoImage);
        formData.append("first_name", vendor?.firstName);
        formData.append("last_name", vendor?.lastName);
        formData.append("email", vendor?.email);
        formData.append("website", vendor?.website);
        formData.append("password", vendor?.password);
        formData.append("status", vendor?.status ?? "A");
        formData.append("commission", vendor?.commission);
        formData.append("taxId", vendor?.taxId);
        formData.append("phone_number", vendor?.phoneNumber);
        formData.append("address", vendor?.address);
        formData.append("display", display);
        const res = await AddVendor(formData, setLoading);
        if (res.status === 200 || res.status === 201) {
          setSuccessOpen(true);
          props.handleVendorGetAll("", 0);
          props.handleClose();
          setLoading(false);
        } else {
          setErrorOpen(true);
          setErrorMsg(res?.data?.message);
          setLoading(false);
        }
      } catch (error) {
        console.log("error: ", error);
        setLoading(false);
      }
    }
  };

  const handleFormData = useCallback(
    (e) => {
      setVendor({ ...vendor, [e.target.name]: e.target.value });
      if (e.target.name === "firstName") {
        setFirstNameError(
          e.target.value.length > 2 ? "" : "* First Name is required"
        );
      } else if (e.target.name === "lastName") {
        setLastNameError(
          e.target.value.length > 2 ? "" : "* Last Name is required"
        );
      } else if (e.target.name === "website") {
        setWebsiteError(
          e.target.value.length > 0 ? "" : "* Website is required"
        );
      } else if (e.target.name === "email") {
        setEmailError(e.target.value.length > 0 ? "" : "* Email is required");
      } else if (e.target.name === "password") {
        setPasswordError(
          e.target.value.length > 0 ? "" : "* Password is required"
        );
      } else if (e.target.name === "commission") {
        setCommissionError(
          e.target.value.length > 0 ? "" : "* Total Charge is required"
        );
      } else if (e.target.name === "phoneNumber") {
        setPhoneNumberError(
          e.target.value.length > 0 ? "" : "* Phone Number is required"
        );
      } else if (e.target.name === "address") {
        setAddressError(
          e.target.value.length > 0 ? "" : "* Address is required"
        );
      }
    },
    [vendor]
  );

  const handleSuccessClose = () => {
    setSuccessOpen(false);
    props.handleClose();
    handleVendorGetAll(props.search || "", props.page + 1);
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const handelLogoImage = (file) => {
    setLogoImage(URL.createObjectURL(file[0]));
    setLogoError(false);
  };

  const classes = useStyles();
  return (
    <>
      <div>
        <Snackbar
          open={isSuccessOpen}
          autoHideDuration={3000}
          onClose={handleSuccessClose}
        >
          <Alert severity="success" onClose={handleSuccessClose}>
            Vendor updated successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={isErrorOpen}
          autoHideDuration={3000}
          onClose={handleErrorClose}
        >
          <Alert severity="error" onClose={handleErrorClose}>
            {errorMsg}
          </Alert>
        </Snackbar>
      </div>
      <Box>
        <Modal open={props.isOpen} onClose={props.handleClose}>
          {fetching ? (
            <CircularProgress />
          ) : (
            <>
              <Box sx={style} className="product-scroll">
                <Grid
                  item
                  xs={12}
                  sx={{
                    marginBottom: "15px",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5">
                      {props.isVendor ? "Edit Vendor" : "Create Vendor"}
                    </Typography>
                  </Stack>
                </Grid>
                <Typography variant="h6" color="#6c757d" marginBottom={0.5}>
                  Logo
                </Typography>
                <div className="upload-logo">
                  <img
                    src={logoImage ? logoImage : default_image}
                    className="logo-img"
                    crossorigin="anonymous"
                    alt="logo"
                  />
                  <input
                    type="file"
                    name="file"
                    accept=".png, .jpg, .jpeg, .pdf"
                    className="upload_input"
                    onChange={(event) => {
                      handelLogoImage(event.target.files);
                    }}
                  />
                </div>
                {logoError && (
                  <p className="invalid-msg mt-2">* Please Add Logo</p>
                )}
                <Grid
                  container
                  spacing={2}
                  sx={{
                    marginTop: "0",
                  }}
                >
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        paddingTop: 0,
                      }}
                    >
                      <TextField
                        fullWidth
                        name="firstName"
                        id="firstName"
                        label={
                          <span>
                            First Name <span className={classes.red}>*</span>
                          </span>
                        }
                        variant="outlined"
                        onChange={handleFormData}
                        value={vendor.firstName}
                      />
                      <div className="invalid-msg mt-2">{firstNameError}</div>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        paddingTop: 0,
                      }}
                    >
                      <TextField
                        fullWidth
                        name="lastName"
                        id="lastName"
                        label={
                          <span>
                            Last Name <span className={classes.red}>*</span>
                          </span>
                        }
                        variant="outlined"
                        onChange={handleFormData}
                        value={vendor.lastName}
                      />
                      <div className="invalid-msg mt-2">{lastNameError}</div>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        paddingTop: 0,
                      }}
                    >
                      <TextField
                        fullWidth
                        name="phoneNumber"
                        id="phoneNumber"
                        label={
                          <span>
                            Phone Number <span className={classes.red}>*</span>
                          </span>
                        }
                        type="number"
                        variant="outlined"
                        onChange={handleFormData}
                        value={vendor.phoneNumber}
                      />
                      <div className="invalid-msg mt-2">{phoneNumberError}</div>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        paddingTop: 0,
                      }}
                    >
                      <TextField
                        fullWidth
                        name="email"
                        id="email"
                        label={
                          <span>
                            Email <span className={classes.red}>*</span>
                          </span>
                        }
                        variant="outlined"
                        onChange={handleFormData}
                        value={vendor.email}
                      />
                      <div className="invalid-msg mt-2">{emailError}</div>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        paddingTop: 0,
                      }}
                    >
                      <TextField
                        fullWidth
                        name="website"
                        id="website"
                        label={
                          <span>
                            Website <span className={classes.red}>*</span>
                          </span>
                        }
                        variant="outlined"
                        onChange={handleFormData}
                        value={vendor.website}
                      />
                      <div className="invalid-msg mt-2">{websiteError}</div>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        paddingTop: 0,
                      }}
                    >
                      <TextField
                        fullWidth
                        name="password"
                        id="password"
                        label={
                          <span>
                            Password <span className={classes.red}>*</span>
                          </span>
                        }
                        variant="outlined"
                        onChange={handleFormData}
                        value={vendor.password}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <div className="invalid-msg mt-2">{passwordError}</div>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        paddingTop: 0,
                      }}
                    >
                      <TextField
                        fullWidth
                        name="commission"
                        id="commission"
                        label={
                          <span>
                            Total Charge In %{" "}
                            <span className={classes.red}>*</span>
                          </span>
                        }
                        variant="outlined"
                        inputMode="numeric"
                        type="number"
                        onChange={handleFormData}
                        value={vendor.commission}
                      />
                      <div className="invalid-msg mt-2">{commissionError}</div>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        paddingTop: 0,
                      }}
                    >
                      <TextField
                        fullWidth
                        name="address"
                        id="address"
                        label={
                          <span>
                            Address <span className={classes.red}>*</span>
                          </span>
                        }
                        variant="outlined"
                        inputMode="numeric"
                        onChange={handleFormData}
                        value={vendor.address}
                      />
                      <div className="invalid-msg mt-2">{addressError}</div>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        paddingTop: 0,
                      }}
                    >
                      <TextField
                        fullWidth
                        name="taxId"
                        id="taxId"
                        label={<span>Tax ID</span>}
                        variant="outlined"
                        inputMode="numeric"
                        onChange={handleFormData}
                        value={vendor.taxId}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={display}
                          onChange={() => {
                            setDisplay(!display);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Show on consumer home page"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        marginTop: "50px",
                      }}
                    >
                      <Button
                        onClick={props.handleClose}
                        type="submit"
                        className={classes.clsBtn}
                        sx={{ py: 2 }}
                        variant="contained"
                      >
                        <p className={classes.prpl}>Close</p>
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        marginTop: "50px",
                      }}
                    >
                      {loading ? (
                        <CircularProgress
                          strokeColor="#fff"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="20"
                          visible
                        />
                      ) : (
                        <Button
                          onClick={handelSubmit}
                          type="submit"
                          className={classes.sbtBtn}
                          sx={{ py: 2 }}
                          variant="contained"
                        >
                          {props.isVendor ? "Update Vendor" : "Create Vendor"}
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Modal>
      </Box>
    </>
  );
}
