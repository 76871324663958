import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  styled,
} from "@mui/material";
import ComponentTitle from "../../components/common_Component/pageTitle/ComponentTitle";
import { useDispatch, useSelector } from "react-redux";
import { GetVendorProfile } from "../../redux/action/GetVendorProfile";
// import { imageURL } from "../../utils/constant";
import "./profile.css";
import { CircularProgress } from "../../components/common_Component/CommonLoader";

const VendorProfile = () => {
  const imageURL = process.env.REACT_APP_IMAGE_URL;
  const dispatch = useDispatch();
  const vendorId = localStorage.getItem("vendorId");

  const profileVendor = useSelector(
    (state) => state?.getVendorProfile?.vendorProfileData
  );
  const fetching = useSelector((state) => state?.getVendorProfile?.fetching);

  useEffect(() => {
    dispatch(GetVendorProfile(vendorId));
  }, []);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <Container maxWidth="lg">
        <ComponentTitle pageTitle="Vendor Profile" />
        <>
          {fetching ? (
            <CircularProgress />
          ) : (
            <Card>
              <div className="cursor-pointer">
                <CardContent>
                  <div className="vendor-img">
                    <img
                      src={`${imageURL + profileVendor?.image}`}
                      crossOrigin="anonymous"
                    />
                  </div>
                  <div>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Item>
                          <span className="text-heading">Company Name:</span>
                          {profileVendor?.first_name}
                        </Item>
                      </Grid>
                      <Grid item xs={6}>
                        <Item>
                          <span className="text-heading">Phone Number:</span>
                          {profileVendor?.phone_number}
                        </Item>
                      </Grid>
                      <Grid item xs={6}>
                        <Item>
                          <span className="text-heading">Email:</span>
                          {profileVendor?.email}
                        </Item>
                      </Grid>
                      <Grid item xs={6}>
                        <Item>
                          <span className="text-heading">Website:</span>
                          {profileVendor?.website}
                        </Item>
                      </Grid>
                      <Grid item xs={6}>
                        <Item>
                          <span className="text-heading">Address:</span>
                          {profileVendor?.address}
                        </Item>
                      </Grid>
                    </Grid>
                  </div>
                </CardContent>
              </div>
            </Card>
          )}
        </>
      </Container>
    </>
  );
};

export default VendorProfile;
