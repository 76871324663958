import { apiInstance } from "../../httpClient";
import { getAdminToken } from "../../utils/Storage";

export const CancelOrderAction = async (body) => {
  const AdminToken = getAdminToken();
  try {
    const res = await apiInstance.post("order/cancel", body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AdminToken}`,
      },
    });
    if (res.status === 200 || res.status === 201) {
      return res.data;
    }
  } catch (err) {
    return null;
  }
};
