import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { GetAllCartProduct } from "../../redux/action/GetAllCartProducts";
import TablePagination from "@mui/material/TablePagination";
import moment from "moment";
import { CircularProgress } from "../../components/common_Component/CommonLoader";
import momenttz from 'moment-timezone';

const CartOrder = ({ search, page, onPageChange }) => {
  const dispatch = useDispatch();

  const getCartDate = useSelector(
    (state) => state?.getCartProduct?.getAllCartProduct
  );

  const fetching = useSelector((state) => state?.getCartProduct?.fetching);

  const handleGetAllCartProduct = (pageNumber, search) => {
    dispatch(GetAllCartProduct(pageNumber + 1, search ?? ""));
  };

  useEffect(() => {
    handleGetAllCartProduct(page, search);
  }, [page, search]);

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const day = dateObject.getDate().toString().padStart(2, "0");
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const year = dateObject.getFullYear();
    // const options = {
    //   hour: "numeric",
    //   minute: "numeric",
    //   second: "numeric",
    // };
    // const time = new Intl.DateTimeFormat("en-US", options).format(dateObject);
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  const columns = [
    { id: "number", label: "No.", minWidth: 30 },
    { id: "userName", label: "User Name", minWidth: 80 },
    { id: "Product Name", label: "Product Name", minWidth: 100 },
    {
      id: "productCount",
      label: "No. Of Product",
      minWidth: 200,
    },
    {
      id: "date",
      label: "Date",
      minWidth: 100,
    },
    {
      id: "Amount",
      label: "Amount",
      minWidth: 50,
    },
  ];

  const formatDateInCentralTime = (date) => {
    const timezone = momenttz.tz.guess(); 
    const centralDate = moment(date).tz(timezone);
    return centralDate.format("MM/DD/YYYY, h:mm A");
  };

  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  key={column?.id}
                  align={column?.align}
                  style={{
                    minWidth: column?.minWidth,
                    backgroundColor: "#a98f57",
                    color: "#fff",
                  }}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fetching ? (
              <TableRow>
                <TableCell colSpan={columns?.length} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {getCartDate?.result?.length ? (
                  getCartDate?.result?.map((e, index) =>
                    e?.products?.map((product, productIndex) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={`${e.id}-${productIndex}`}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            {(e?.user?.first_name ? e?.user?.first_name : "-") +
                              " " +
                              (e?.user?.last_name ? e?.user?.last_name : "-")}
                          </TableCell>
                          <TableCell>
                            <p className="text-overflow" title={product?.title}>
                              {product?.title}
                            </p>
                          </TableCell>
                          <TableCell align="center">
                            {e?.products?.length}
                          </TableCell>
                          <TableCell>
                            {formatDateInCentralTime(product?.createdAt)}
                          </TableCell>
                          <TableCell>
                            ${Number(e?.totalPrice).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns?.length} align="center">
                      No orders available.
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={getCartDate?.meta?.totalCount}
        rowsPerPage={10}
        page={page}
        onPageChange={onPageChange}
        labelDisplayedRows={({ from, to, count }) =>
          !count ? "0-0 of 0" : `${from}-${to} of ${count}`
        }
      />
    </>
  );
};

export default CartOrder;
