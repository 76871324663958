import SvgColor from "../../../components/svg-color";

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const commonNavConfig = [
  {
    title: "Dashboard",
    path: "/",
    icon: icon("ic_home"),
  },
  {
    title: "Products",
    path: "/product",
    icon: icon("ic_product"),
  },
  {
    title: "Orders",
    path: "/orders",
    icon: icon("ic_cart"),
  },
  // {
  //   title: "Transactions",
  //   path: "/transaction",
  //   icon: icon("ic_transaction"),
  // },
];

const vendorNavConfig = [
  ...commonNavConfig,
  // {
  //   title: "Reviews",
  //   path: "/review",
  //   icon: icon("ic_shoppingCart"),
  // },

];

export { vendorNavConfig, commonNavConfig };

