import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  imageSlt: {
    width: "380px",
    height: "120px",
    backgroundColor: "#a98f57",
    borderRedius: "20px",
    flexDirection: "column",
  },
  mT: {
    marginTop: "20px",
  },
  img: {
    height: "100px",
    width: "100px",
    margin: "10px",
    border: "1px solid #999999",
  },
  clsBtn: {
    width: "350px",
    height: "45px",
    marginLeft: "20px",
    backgroundColor: "transparent",
  },
  sbtBtn: {
    width: "350px",
    height: "45px",
    marginLeft: "10px",
    backgroundColor: "#a98f57",
  },
  red: {
    color: "red",
  },
  prpl: {
    color: "#a98f57"
  },
});
