import * as React from "react";
import { useState, useCallback, useEffect } from "react";
import {
  Typography,
  Box,
  TextField,
  Grid,
  Stack,
  IconButton,
  Button,
  Modal,
  InputAdornment,
  CircularProgress,
  Alert,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { UpdateUserAction } from "../../redux/action/UpdateUserAction";
import { useDispatch, useSelector } from "react-redux";
import { GetAllUserAction } from "../../redux/action/GetUserAction";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AddUser } from "../../redux/action/AddUserAction";
import { EMAIL_REGEX } from "../../utils/constant";
import Snackbar from "@mui/material/Snackbar";
import { useStyles } from "./commonCss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: 2,
  overflowY: "scroll",
};
export default function UserModal(props) {
  const dispatch = useDispatch();

  const handleUserGetAll = (search, page) =>
    dispatch(GetAllUserAction(search, page));

  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [cityError, setCityError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [stateError, setStateError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [isSuccessOpen, setSuccessOpen] = useState(false);
  const [isErrorOpen, setErrorOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const userDetails = useSelector((state) => state.setUserDetail.setUserData);

  const [user, setUser] = useState({
    id: userDetails?._id || "",
    firstName: userDetails?.first_name || "",
    lastName: userDetails?.last_name || "",
    email: userDetails?.email || "",
    status: userDetails?.status || "",
    password: userDetails?.password || "",
    address: userDetails?.address || "",
    address2: userDetails?.address2 || "",
    city: userDetails?.city || "",
    state: userDetails?.state || "",
    country: userDetails?.country || "USA",
    pincode: userDetails?.pincode || "",
    phoneNumber: userDetails?.phone_number || "",
  });

  useEffect(() => {
    if ((props.isUser && userDetails) || (props.isOpen && props.data)) {
      setIsModalOpen(true);
      setUser({
        id: userDetails._id || "",
        firstName: userDetails.first_name || "",
        lastName: userDetails.last_name || "",
        email: userDetails.email || "",
        status: userDetails.status || "",
        password: userDetails.password || "",
        address: userDetails.address || "",
        address2: userDetails.address2 || "",
        city: userDetails.city || "",
        state: userDetails.state || "",
        country: userDetails.country || "USA",
        pincode: userDetails.pincode || "",
        phoneNumber: userDetails.phone_number || "",
      });
    } else {
      setIsModalOpen(false);
      setUser({
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        status: "",
        password: "",
        address: "",
        address2: "",
        city: "",
        state: "",
        country: "USA",
        pincode: "",
        phoneNumber: "",
      });
    }
  }, [props.isUser, userDetails, props.isOpen, props.data]);

  const handelSubmit = async () => {
    if (
      !user?.firstName?.length &&
      !user?.lastName?.length &&
      !user?.email?.length &&
      !user?.password?.length &&
      !user?.address?.length &&
      !user?.pincode?.length &&
      !user?.phoneNumber?.length &&
      !user?.city?.length &&
      !user?.state.length &&
      !user?.country.length
    ) {
      setFirstNameError("* First Name is required");
      setLastNameError("* Last Name is required");
      setEmailError("* Email is required");
      setPasswordError("* Password is required");
      setAddressError("* Address is required");
      setCityError("* City Name is required");
      setStateError("* State Name is required");
      setCountryError("* Country Name is required");
      setPincodeError("* Zip is required");
      setPhoneNumberError("* Phone Number is required");
      return;
    }
    if (!EMAIL_REGEX.test(user?.email)) {
      setEmailError("* Invalid Email");
      return;
    }
    if (user?.phoneNumber.toString()?.length !== 10) {
      setPhoneNumberError("* Valid Phone Number is required");
      return;
    }
    setLoading(true);
    if (props.isUser) {
      try {
        const handleBody = {
          id: user?.id,
          first_name: user?.firstName,
          last_name: user?.lastName,
          email: user?.email,
          password: user?.password,
          address: user?.address,
          address2: user?.address2,
          city: user?.city,
          state: user?.state,
          country: user?.country,
          pincode: user?.pincode,
          phone_number: Number(user?.phoneNumber),
          status: user?.status,
        };
        const response = await UpdateUserAction(handleBody);
        if (response.status === 200 || 201) {
          setSuccessOpen(true);
          props.handleClose();
        } else {
          setErrorOpen(true);
          setErrorMsg(response.data.displayMsg);
        }
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const data = {
          first_name: user?.firstName,
          last_name: user?.lastName,
          email: user?.email,
          password: user?.password,
          address: user?.address,
          address2: user?.address2,
          city: user?.city,
          state: user?.state,
          country: user?.country,
          pincode: user?.pincode,
          phone_number: Number(user?.phoneNumber),
        };
        const res = await AddUser(data, setLoading);
        if (res.status === 200 || res.status === 201) {
          setSuccessOpen(true);
          props.handleClose();
        } else {
          setErrorOpen(true);
          setErrorMsg(res.data.displayMsg);
        }
      } catch (error) {
        console.log(error.response);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFormData = useCallback(
    (e) => {
      setUser({ ...user, [e.target.name]: e.target.value });
      if (e.target.name === "firstName") {
        setFirstNameError(
          e.target.value.length > 2 ? "" : "* First Name is required"
        );
      } else if (e.target.name === "lastName") {
        setLastNameError(
          e.target.value.length > 2 ? "" : "* Last Name is required"
        );
      } else if (e.target.name === "email") {
        setEmailError(e.target.value.length > 0 ? "" : "* Email is required");
      } else if (e.target.name === "password") {
        setPasswordError(
          e.target.value.length > 0 ? "" : "* Password is required"
        );
      } else if (e.target.name === "address") {
        setAddressError(
          e.target.value.length > 0 ? "" : "* Address is required"
        );
      } else if (e.target.name === "city") {
        setCityError(
          e.target.value.length > 0 ? "" : "* City Name is required"
        );
      } else if (e.target.name === "state") {
        setStateError(
          e.target.value.length > 0 ? "" : "* State Name is required"
        );
      } else if (e.target.name === "country") {
        setCountryError(
          e.target.value.length > 0 ? "" : "* Country Name is required"
        );
      } else if (e.target.name === "pincode") {
        setPincodeError(e.target.value.length > 0 ? "" : "* Zip is required");
      } else if (e.target.name === "phoneNumber") {
        const cleanedPhoneNumber = e.target.value
          .replace(/\D/g, "")
          .slice(0, 10);
        setUser({ ...user, [e.target.name]: cleanedPhoneNumber });
        setPhoneNumberError(
          cleanedPhoneNumber.length === 10
            ? ""
            : "* Valid Phone Number is required"
        );
      }
    },
    [user]
  );

  const handleSuccessClose = () => {
    setSuccessOpen(false);
    props.handleClose();
    handleUserGetAll(props.search || "", props.page + 1);
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const stateOptions = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const handleStateChange = (e) => {
    setUser({ ...user, state: e.target.value });
  };

  const classes = useStyles();
  return (
    <>
      <div>
        <Snackbar
          open={isSuccessOpen}
          autoHideDuration={3000}
          onClose={handleSuccessClose}
        >
          <Alert severity="success" onClose={handleSuccessClose}>
            User {props.isUser ? "updated" : "created"} successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={isErrorOpen}
          autoHideDuration={3000}
          onClose={handleErrorClose}
        >
          <Alert severity="error" onClose={handleErrorClose}>
            {errorMsg}
          </Alert>
        </Snackbar>
      </div>
      <Box>
        <Modal open={props.isOpen} onClose={props.handleClose}>
          <Box sx={style} className="product-scroll">
            <Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mb: 3 }}
                >
                  <Typography variant="h5">
                    {props.isUser ? "Edit User" : "Create User"}
                  </Typography>
                </Stack>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      maxWidth: "380px",
                      my: 2,
                    }}
                  >
                    <TextField
                      fullWidth
                      name="firstName"
                      id="firstName"
                      label={
                        <span>
                          First Name <span className={classes.red}>*</span>
                        </span>
                      }
                      variant="outlined"
                      onChange={handleFormData}
                      value={user.firstName}
                    />
                    <div className="invalid-msg mt-2">{firstNameError}</div>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      maxWidth: "380px",
                      my: 2,
                    }}
                  >
                    <TextField
                      fullWidth
                      name="lastName"
                      id="lastName"
                      label={
                        <span>
                          Last Name <span className={classes.red}>*</span>
                        </span>
                      }
                      variant="outlined"
                      onChange={handleFormData}
                      value={user.lastName}
                    />
                    <div className="invalid-msg mt-2">{lastNameError}</div>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      maxWidth: "380px",
                      my: 2,
                    }}
                  >
                    <TextField
                      fullWidth
                      name="email"
                      id="email"
                      label={
                        <span>
                          Email <span className={classes.red}>*</span>
                        </span>
                      }
                      variant="outlined"
                      onChange={handleFormData}
                      value={user.email}
                    />
                    <div className="invalid-msg mt-2">{emailError}</div>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      maxWidth: "380px",
                      my: 2,
                    }}
                  >
                    <TextField
                      fullWidth
                      name="password"
                      id="password"
                      label={
                        <span>
                          Password <span className={classes.red}>*</span>
                        </span>
                      }
                      variant="outlined"
                      onChange={handleFormData}
                      value={user.password}
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div className="invalid-msg mt-2">{passwordError}</div>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      maxWidth: "380px",
                      my: 2,
                    }}
                  >
                    <TextField
                      fullWidth
                      name="phoneNumber"
                      id="phoneNumber"
                      label={
                        <span>
                          Phone Number <span className={classes.red}>*</span>
                        </span>
                      }
                      variant="outlined"
                      inputMode="numeric"
                      onChange={handleFormData}
                      value={user.phoneNumber}
                    />
                    <div className="invalid-msg mt-2">{phoneNumberError}</div>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      maxWidth: "380px",
                      my: 2,
                    }}
                  >
                    <TextField
                      fullWidth
                      name="address"
                      id="address"
                      label={
                        <span>
                          Address Line 1<span className={classes.red}>*</span>
                        </span>
                      }
                      variant="outlined"
                      onChange={handleFormData}
                      value={user.address}
                    />
                    <div className="invalid-msg mt-2">{addressError}</div>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      maxWidth: "380px",
                      my: 2,
                    }}
                  >
                    <TextField
                      fullWidth
                      name="address"
                      id="address"
                      label={<span>Address Line 2</span>}
                      variant="outlined"
                      onChange={handleFormData}
                      value={user.address2}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      maxWidth: "380px",
                      my: 2,
                    }}
                  >
                    <TextField
                      fullWidth
                      name="city"
                      id="city"
                      label={
                        <span>
                          City <span className={classes.red}>*</span>
                        </span>
                      }
                      variant="outlined"
                      onChange={handleFormData}
                      value={user.city}
                    />
                    <div className="invalid-msg mt-2">{cityError}</div>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      maxWidth: "380px",
                      my: 2,
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-autowidth-label">
                        State <span className={classes.red}>*</span>
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        onChange={handleStateChange}
                        value={user.state}
                        autoWidth
                        label="State"
                        className="state-select"
                      >
                        {stateOptions.map((state) => (
                          <MenuItem key={state} value={state}>
                            {state}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <div className="invalid-msg mt-2">{stateError}</div>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      maxWidth: "380px",
                      my: 2,
                    }}
                  >
                    <TextField
                      fullWidth
                      name="country"
                      id="country"
                      label={
                        <span>
                          Country <span className={classes.red}>*</span>
                        </span>
                      }
                      variant="outlined"
                      onChange={handleFormData}
                      value={user.country}
                    />
                    <div className="invalid-msg mt-2">{countryError}</div>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      maxWidth: "380px",
                      my: 2,
                    }}
                  >
                    <TextField
                      fullWidth
                      name="pincode"
                      id="pincode"
                      label={
                        <span>
                          Zip <span className={classes.red}>*</span>
                        </span>
                      }
                      variant="outlined"
                      inputMode="numeric"
                      onChange={handleFormData}
                      value={user.pincode}
                    />
                    <div className="invalid-msg mt-2">{pincodeError}</div>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      maxWidth: "380px",
                      marginTop: "50px",
                    }}
                  >
                    <Button
                      onClick={props.handleClose}
                      type="submit"
                      className={classes.clsBtn}
                      sx={{ py: 2 }}
                      variant="contained"
                    >
                      <p className={classes.prpl}>Close</p>
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      maxWidth: "380px",
                      marginTop: "50px",
                    }}
                  >
                    {loading ? (
                      <CircularProgress
                        strokeColor="#fff"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="20"
                        visible
                      />
                    ) : (
                      <Button
                        onClick={handelSubmit}
                        type="submit"
                        className={classes.sbtBtn}
                        sx={{ py: 2 }}
                        variant="contained"
                      >
                        {props.isUser ? "Update" : "Create User"}
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Box>
    </>
  );
}
