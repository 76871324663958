import { useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import { MenuItem, Avatar, IconButton, Popover } from "@mui/material";
// mocks_
import account from "../../../_mock/account";
import { removeAdminSession } from "../../../utils/Storage";
import { useAuthContext } from "../AuthContext";

export default function AccountPopover() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const { isVendor } = useAuthContext();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleIsClose = () => {
    setOpen(null);
  };
  const handleClose = () => {
    removeAdminSession();
    navigate("/login");
    setOpen(null);
    if (isVendor) {
      localStorage.removeItem("vendorId");
    }
  };
  const changePassword = () => {
    console.log("changePassword");
    setOpen(null);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleIsClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        {isVendor && (
          <>
            <MenuItem
              onClick={() => {
                navigate("/vendor-profile");
                setOpen(null);
              }}
              sx={{ m: 1 }}
            >
              Profile
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/change-password");
                setOpen(null);
              }}
              sx={{ m: 1 }}
            >
              Change Password
            </MenuItem>
          </>
        )}
        <MenuItem onClick={handleClose} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
