import * as React from "react";
import {
  Alert,
  Button,
  Card,
  Chip,
  Container,
  Grid,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import ComponentTitle from "../../components/common_Component/pageTitle/ComponentTitle";
import { useLocation, useNavigate } from "react-router-dom";
import { useStyles } from "./orderStyle";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { GetOrderDetailAction } from "../../redux/action/OrderDetailAction";
import { useEffect } from "react";
import { useState } from "react";
import { OrderTrackingDetailsAction } from "../../redux/action/OrderTrackingDetailsAction";
import { useAuthContext } from "../../layouts/dashboard/AuthContext";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import momenttz from "moment-timezone";
import { CancelOrderAction } from "../../redux/action/CancelOrderAction";
import swal from "sweetalert";
import {
  CircularProgress,
  RotatingLines,
} from "../../components/common_Component/CommonLoader";

const columns = [
  { id: "Item", label: "Item", minWidth: 150 },
  { id: "bean", label: "Bean", minWidth: 30 },
  {
    id: "Quantity",
    label: "Quantity",
    minWidth: 30,
  },
  {
    id: "unitPrice",
    label: "Unit Price",
    minWidth: 30,
  },
  {
    id: "totalUnit",
    label: "Unit Total",
    minWidth: 100,
  },
  {
    id: "shipping",
    label: "Shipping Charge",
    minWidth: 140,
  },
  {
    id: "totalPrice",
    label: "Total Price",
    minWidth: 100,
  },
];

const trackingColumns = [
  { id: "tracking_number", label: "Tracking Number", minWidth: 50 },
  { id: "tracking_link", label: "Tracking Link", minWidth: 40 },
  { id: "carrier", label: "Carrier", minWidth: 40 },
  { id: "delivery_date", label: "Delivery Date", minWidth: 40 },
  { id: "remarks", label: "Remarks", minWidth: 40 },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: 2,
  overflowY: "scroll",
};

export default function OrderDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderId = location.state._id;
  const [orderStatusO, setOrderStatusO] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isSuccessOpen, setSuccessOpen] = useState(false);
  const [isErrorOpen, setErrorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [trackingNo, setTrackingNo] = useState("");
  const [trackingLink, setTrackingLink] = useState("");
  const [carrier, setCarrier] = useState("");
  const [remarks, setRemarks] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [submitTracking, setSubmitTracking] = useState(true);
  const [attachment, setAttachment] = useState();
  const [attachmentFileName, setAttachmentFileName] = useState("");
  const [loading, setLoading] = useState(true);

  const orderDetail = useSelector(
    (state) => state.GetOrderDetail.orderGetDetail
  );

  const userDetails = orderDetail?.userDetails;

  const fetching = useSelector(
    (state) => state.GetOrderDetail.orderGetDetail?.fetching
  );

  const handleDetails = async () => {
    const orderId = location.state._id;
    dispatch(GetOrderDetailAction(orderId));
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
    handleDetails();
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  const tracking =
    orderDetail?.result?.length > 0 &&
    orderDetail?.result?.reduce((item) => {
      return item;
    });

  const orderStatus = orderDetail?.result?.[0]?.status;

  const handleSuccessClose = () => {
    setSuccessOpen(false);
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const handleTrackingNoChange = (event) => {
    const { value } = event.target;
    setTrackingNo(value);
  };

  const handleTrackingLinkChange = (event) => {
    const { value } = event.target;
    setTrackingLink(value);
  };

  const handleCarrierChange = (event) => {
    const { value } = event.target;
    setCarrier(value);
  };

  const handleRemarkChange = (event) => {
    const { value } = event.target;
    setRemarks(value);
  };

  const handleSubmit = async () => {
    setSubmitTracking(!submitTracking);
    const formData = new FormData();
    formData.append("order_id", orderId);
    formData.append("tracking_number", trackingNo);
    formData.append("tracking_link", trackingLink);
    formData.append("remarks", remarks);
    formData.append("carrier", carrier);
    formData.append("delivery_date", deliveryDate);
    if (attachment) {
      formData.append("email_attachment", attachment);
    }

    setIsLoading(true);
    try {
      const response = await OrderTrackingDetailsAction(formData);
      if (response?.isSuccess) {
        setOpenModal(false);
        setOrderStatusO("");
        handleDetails();
        setSubmitTracking(false);
        toast.success(response?.data?.techMsg);
      } else {
        toast.error("Your request is not executed.");
      }

      setIsLoading(false);
    } catch (error) {
      console.error("error", error);
      setIsLoading(false);
    }
  };
  const handleChangeDoc = (files) => {
    setAttachment(files[0]);
    setAttachmentFileName(files[0].name);
  };

  // Cancel Order
  const handleCancelOrder = () => {
    swal({
      title: "Are you Sure",
      text: `Are you sure that you want to cancel this Order?`,
      icon: "warning",
      buttons: {
        confirm: {
          text: "Yes, Confirm",
          value: true,
          visible: true,
          className: "btn btn-danger",
          closeModal: true,
        },
      },
      dangerMode: false,
    }).then(async (willSave) => {
      if (willSave) {
        setLoading(true);
        try {
          const res = await CancelOrderAction({ order_id: orderId });
          if (res?.isSuccess) {
            setOpenModal(false);
            setOrderStatusO("");
            handleDetails();
            setSubmitTracking(false);
            setLoading(false);
            toast.success(res?.displayMsg);
          }
        } catch (error) {
          console.log("Error Cancel order:", error);
        }
      }
    });
  };

  const formatDateInCentralTime = (date) => {
    const timezone = momenttz.tz.guess();
    const centralDate = moment(date).tz(timezone);
    return centralDate.format("MM/DD/YYYY");
  };

  const classes = useStyles();
  return (
    <>
      {loading || fetching ? (
        <CircularProgress />
      ) : (
        <Container className={classes.container}>
          <div className={classes.dRow}>
            <ArrowBackIcon style={{ margin: "2px" }} onClick={handleGoBack} />
            <ComponentTitle pageTitle="Order Details" />
          </div>

          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <Grid className={classes.dCntr}>
              <Box sx={{ width: "65%", marginTop: "20px" }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Grid> */}
            {/* {!isAdmin && (
              <FormControl
                sx={{ width: 200, display: "flex", justifyContent: "center" }}
              >
                <Select
                  value={orderStatusO}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="" style={{ fontSize: "14px" }}>
                    Change Status
                  </MenuItem>
                  {Object.keys(orderStatusMapping).map((code) => (
                    <MenuItem
                      key={code}
                      value={code}
                      style={{ fontSize: "14px" }}
                    >
                      {orderStatusMapping[code]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )} */}
            <div>
              <div>
                Order Status :{" "}
                {orderStatus === "P" && (
                  <Chip label="Pending" size="small" color="warning" />
                )}
                {orderStatus === "C" && (
                  <Chip label="Cart" size="small" color="warning" />
                )}
                {orderStatus === "CL" && (
                  <Chip label="Cancelled" size="small" color="error" />
                )}
                {orderStatus === "PD" && (
                  <Chip label="Payment Declined" size="small" color="error" />
                )}
                {orderStatus === "PF" && (
                  <Chip label="Payment Failed" size="small" color="error" />
                )}
                {orderStatus === "S" && (
                  <Chip label="Shipped" size="small" color="secondary" />
                )}
                {orderStatus === "PC" && (
                  <Chip label="Paid" size="small" color="success" />
                )}
              </div>
              Order Id:{" "}
              {orderDetail?.result && orderDetail?.result[0]?.orderNumber}
            </div>
            {!(
              orderStatus === "P" ||
              orderStatus === "C" ||
              orderStatus === "CL" ||
              orderStatus === "PD" ||
              orderStatus === "PF" ||
              orderStatus === "S"
            ) && (
              <div>
                <Button
                  variant="contained"
                  className="me-10"
                  onClick={() => setOpenModal(true)}
                >
                  Mark as Shipped
                </Button>
                <Button variant="contained" onClick={handleCancelOrder}>
                  Cancel Order
                </Button>
              </div>
            )}
          </div>
          <div>
            <Snackbar
              open={isSuccessOpen}
              autoHideDuration={3000}
              onClose={handleSuccessClose}
            >
              <Alert severity="success" onClose={handleSuccessClose}>
                "Order updated successfully!"
              </Alert>
            </Snackbar>
            <Snackbar
              open={isErrorOpen}
              autoHideDuration={3000}
              onClose={handleErrorClose}
            >
              <Alert severity="error" onClose={handleErrorClose}>
                "Failed to update Order."
              </Alert>
            </Snackbar>
          </div>
          <Grid
            container
            spacing={2}
            style={{ marginTop: "40px", padding: "0px 20px 0px 0px" }}
          >
            <Grid item xs={12} className={classes.tbl1}>
              <Card
                style={{
                  marginLeft: "10px",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Typography
                  className={classes.title}
                  style={{ padding: "10px", marginTop: 0 }}
                >
                  ITEMS FROM ORDER
                </Typography>
                <Paper
                  sx={{
                    width: "100%",
                    overflow: "hidden",
                    borderRadius: "0px",
                  }}
                >
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                                backgroundColor: "#eef2f7",
                              }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orderDetail?.result?.length > 0 ? (
                          orderDetail?.result?.map((row, index) => {
                            return row?.accounting?.cartAccountingList?.map(
                              (item, itemIndex) => (
                                <TableRow key={`${index}-${itemIndex}`}>
                                  <TableCell>
                                    {item?.productName ||
                                      "Product Name Not Found"}
                                  </TableCell>
                                  <TableCell>{item?.bean}</TableCell>
                                  <TableCell>{item?.quantity}</TableCell>
                                  <TableCell>
                                    ${Number(item?.unitPrice).toFixed(2)}
                                  </TableCell>
                                  <TableCell>
                                    ${(item?.totalPrice).toFixed(2)}
                                  </TableCell>
                                  <TableCell>
                                    ${row?.accounting?.shippingCost?.toFixed(2)}
                                  </TableCell>
                                  <TableCell>
                                    ${row?.accounting?.finalTotal?.toFixed(2)}
                                  </TableCell>
                                </TableRow>
                              )
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell>No order details available</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Card>
            </Grid>
          </Grid>
          {orderStatus === "S" && tracking?.trackingDetails && (
            <Grid
              container
              spacing={2}
              style={{ marginTop: "40px", padding: "0px 20px 0px 0px" }}
            >
              <Grid item xs={12} md={12} style={{ paddingTop: 0 }}>
                <Card
                  style={{
                    height: "100%",
                    width: "100%",
                    padding: "20px",
                  }}
                >
                  <Typography
                    className={classes.title}
                    style={{ padding: "10px", marginTop: 10 }}
                  >
                    TRACKING DETAILS
                  </Typography>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {trackingColumns?.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                                backgroundColor: "#eef2f7",
                              }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orderDetail?.result?.length > 0 ? (
                          orderDetail?.result?.map((row, index) => {
                            return (
                              <TableRow key={`${index}`}>
                                <TableCell>
                                  {row?.trackingDetails?.tracking_number
                                    ? row?.trackingDetails?.tracking_number
                                    : "-"}
                                </TableCell>
                                <TableCell>
                                  {row?.trackingDetails?.tracking_link
                                    ? row?.trackingDetails?.tracking_link
                                    : "-"}
                                </TableCell>
                                <TableCell>
                                  {row?.trackingDetails?.carrier
                                    ? row?.trackingDetails?.carrier
                                    : "-"}
                                </TableCell>
                                <TableCell>
                                  {row?.trackingDetails?.delivery_date
                                    ? formatDateInCentralTime(
                                        row?.trackingDetails?.delivery_date
                                      )
                                    : "-"}
                                </TableCell>
                                <TableCell>
                                  {row?.trackingDetails?.remarks
                                    ? row?.trackingDetails?.remarks
                                    : "-"}
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell>No orders available</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            spacing={2}
            style={{ marginTop: "40px", padding: "0px 30px 0px 0px" }}
          >
            <Grid item xs={4} md={4}>
              <Card
                style={{
                  height: "100%",
                  width: "100%",
                  padding: "5px 10px 10px 10px",
                }}
              >
                <Typography
                  className={classes.title}
                  style={{ padding: "10px", marginTop: 10 }}
                >
                  SHIPPING INFORMATION
                </Typography>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <h4
                    className={classes.title}
                    style={{ marginTop: "0px", marginLeft: "10px" }}
                  >
                    Shipping Address:
                  </h4>
                  <p
                    className={classes.p}
                    style={{
                      marginTop: "2px",
                      overflowX: "hidden",
                    }}
                  >
                    {orderDetail?.result &&
                      orderDetail?.result[0]?.shippingAddress}
                  </p>
                </div>
              </Card>
            </Grid>
            <Grid item xs={4} md={4}>
              <Card
                style={{
                  marginLeft: "10px",
                  height: "100%",
                  width: "100%",
                  padding: "5px 10px 10px 10px",
                }}
              >
                <Typography
                  className={classes.title}
                  style={{ padding: "10px", marginTop: 10 }}
                >
                  BILLING INFORMATION
                </Typography>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <h4
                    className={classes.title}
                    style={{ marginTop: "0px", marginLeft: "10px" }}
                  >
                    Payment Status:
                  </h4>
                  <p
                    className={classes.p}
                    style={{ marginTop: "2px", marginLeft: "7px" }}
                  >
                    {orderStatus === "P" && "Pending"}
                    {orderStatus === "C" && "Cart"}
                    {orderStatus === "CL" && "Cancelled"}
                    {orderStatus === "PD" && "Payment Declined"}
                    {orderStatus === "PF" && "Payment Failed"}
                    {orderStatus === "S" && "Shipped"}
                    {orderStatus === "PC" && "Paid"}
                  </p>
                </div>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <h4
                    className={classes.title}
                    style={{ marginTop: "0px", marginLeft: "10px" }}
                  >
                    Transaction Id:
                  </h4>

                  <p
                    className={classes.p}
                    style={{
                      marginTop: "0px",
                      marginLeft: "10px",
                      marginRight: "0px",
                      overflowX: "hidden",
                    }}
                  >
                    {orderDetail?.result && orderDetail?.result[0]?.paymentId}
                  </p>
                </div>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <h4
                    className={classes.title}
                    style={{ marginTop: "0px", marginLeft: "10px" }}
                  >
                    Billing Address:
                  </h4>
                  <p
                    className={classes.p}
                    style={{
                      marginTop: "2px",
                      overflowX: "hidden",
                    }}
                  >
                    {orderDetail?.result &&
                      orderDetail?.result[0]?.billingAddress}
                  </p>
                </div>
              </Card>
            </Grid>
            {userDetails && (
              <Grid item xs={4} md={4}>
                <Card
                  style={{
                    marginLeft: "20px",
                    height: "100%",
                    width: "100%",
                    padding: "5px 10px 10px 10px",
                  }}
                >
                  <Typography
                    className={classes.title}
                    style={{ marginTop: 10 }}
                  >
                    CUSTOMER INFO
                  </Typography>
                  <div>
                    <h4
                      className={classes.title}
                      style={{ marginBottom: "0px" }}
                    >
                      {userDetails.first_name + " " + userDetails.last_name}
                    </h4>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <h4 className={classes.title} style={{}}>
                        Address:{" "}
                      </h4>
                      <p className={classes.p}>
                        {userDetails.address},{userDetails.address2}
                        {userDetails.city},{userDetails.state},
                        {userDetails.country},{userDetails.pincode}
                      </p>
                    </div>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <h4 className={classes.title} style={{}}>
                        Email:{" "}
                      </h4>
                      <p className={classes.p}>{userDetails.email}</p>
                    </div>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <h4 className={classes.title} style={{}}>
                        Phone Number:{" "}
                      </h4>
                      <p className={classes.p}>{userDetails.phone_number}</p>
                    </div>
                  </div>
                </Card>
              </Grid>
            )}
          </Grid>

          {/* Shipped Order Model */}
          <Modal
            open={openModal}
            className="overflow-model"
            onClose={() => setOpenModal(false)}
          >
            <Box sx={style}>
              <Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        my: 1,
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Tracking No."
                        name="trackingNo"
                        value={trackingNo}
                        onChange={handleTrackingNoChange}
                      />
                    </Box>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        my: 1,
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Tracking Link"
                        name="trackingLink"
                        value={trackingLink}
                        onChange={handleTrackingLinkChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        my: 1,
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Add Carrier"
                        name="carrier"
                        value={carrier}
                        onChange={handleCarrierChange}
                      />
                    </Box>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        my: 1,
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Remarks"
                        name="remarks"
                        value={remarks}
                        onChange={handleRemarkChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} style={{ paddingTop: "0px" }}>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        my: 1,
                      }}
                    >
                      <ReactDatePicker
                        className="datepicker_input"
                        placeholderText="Delivery Date"
                        selected={deliveryDate}
                        minDate={new Date()}
                        onChange={(date) => setDeliveryDate(date)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} style={{ paddingTop: "0px" }}>
                    <Box
                      sx={{
                        maxWidth: "380px",
                        my: 1,
                      }}
                    >
                      <button className="doc_button">
                        <input
                          type="file"
                          id="attachment"
                          name="attachment"
                          onChange={(e) => {
                            handleChangeDoc(e.target.files);
                          }}
                          accept=".png, .jpg, .jpeg,"
                          className="doc_input"
                        />
                        <p>
                          {attachmentFileName
                            ? attachmentFileName
                            : "Attachment"}
                        </p>
                      </button>
                    </Box>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{
                      maxWidth: "380px",
                      mt: 3,
                    }}
                  >
                    <Button
                      onClick={handleSubmit}
                      type="submit"
                      className={classes.sbtBtn}
                      sx={{ py: 2 }}
                      variant="contained"
                    >
                      {isLoading ? <RotatingLines /> : "Submit"}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Container>
      )}
    </>
  );
}
