/* eslint-disable no-unused-vars */
import * as React from "react";
import { useState, useEffect } from "react";
import {
  Container,
  Snackbar,
  Alert,
  Card,
  Avatar,
  Button,
  Tooltip,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import ComponentTitle from "../../components/common_Component/pageTitle/ComponentTitle";
import Iconify from "../../components/iconify";
import VendorModal from "../../components/common_Component/VendorModal";
import UserListToolbar from "../../sections/@dashboard/user/UserListToolbar";
import styled from "@emotion/styled";
import { useStyles } from "./vendorStyle";
import { GetAllVendorAction } from "../../redux/action/GetVendorAction";
import { useDispatch, useSelector } from "react-redux";
import { DeleteVendorAction } from "../../redux/action/DeleteVendorAction";
import RemoveModal from "../../components/common_Component/RemoveModal";
import { useNavigate } from "react-router-dom";
import { VendorDetailsAction } from "../../redux/action/VendorDetailsAction";
import { Flag } from "@mui/icons-material";
import { CircularProgress } from "../../components/common_Component/CommonLoader";

const columns = [
  { id: "first_name", label: "First Name", minWidth: 60 },
  { id: "last_name", label: "Last Name", minWidth: 60 },
  {
    id: "email",
    label: "Email",
    minWidth: 80,
  },
  // {
  //   id: "status",
  //   label: "Status",
  //   minWidth: 60,
  // },
  {
    id: "commission",
    label: "Total Charge In %",
    minWidth: 50,
  },
  {
    id: "Action",
    label: "Action",
    minWidth: 100,
  },
];

const AddButton = styled(Button)({
  background: "#a98f57",
  color: "#f1f3fa",
  width: "150px",
  height: "45px",
  marginTop: "10px",
});

const ActiveButton = styled(Button)({
  background: "#0acf97",
  color: "#fff",
  width: "20px",
  height: "20px",
});

export default function VendorPage() {
  const [removeModal, setRemoveModal] = useState(false);
  const handleOpenModal = () => setRemoveModal(true);
  const handleCloseModal = () => setRemoveModal(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [vendorData, setVendorData] = useState({});
  const [editVendorModal, setEditVendorModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const handleOpenEditModal = () => setEditVendorModal(true);

  const handleCloseEditModal = () => {
    setEditVendorModal(false);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleVendorGetAll = (search, pageNumber) => {
    dispatch(GetAllVendorAction(search, pageNumber + 1));
  };

  const handleVendorGetAllState = useSelector(
    (state) => state.GetAllVendor.vendorGetAllData
  );

  const fetching = useSelector((state) => state.GetAllVendor.fetching);

  const vendorDetail = useSelector(
    (state) => state.setVendorDetail.setVendorData
  );

  const handleChangePage = (event, page) => {
    setPage(page);
    handleVendorGetAll(search, page);
  };

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSearchInput = (e) => {
    setSearch(e.target.value);
    handleVendorGetAll(e.target.value, 0);
  };

  useEffect((apiResponse) => {
    handleVendorGetAll("", page);
  }, []);

  const handleShowAlert = () => {
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 3000);
  };

  const handelDeleteItem = async (id) => {
    const data = {
      id: id,
    };
    try {
      await DeleteVendorAction(data);
      handleCloseModal();
      handleVendorGetAll("", page);
      handleShowAlert();
    } catch (error) {
      console.log(error);
    }
  };

  const classes = useStyles();

  const handleNavigate = (data) => {
    navigate("/vendorDetails", { state: data });
  };

  return (
    <>
      {fetching ? (
        <CircularProgress />
      ) : (
        <Container className={classes.container}>
          <ComponentTitle pageTitle="Vendors" />
          <div className={classes.mainView}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "0px",
              }}
            >
              <UserListToolbar
                search={search}
                handleSearch={handleSearchInput}
                name="Name"
              />
              <AddButton variant="text" onClick={handleOpen}>
                Create Vendor
              </AddButton>
            </div>
            <Card
              sx={{
                marginBottom: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              backgroundColor: "#a98f57",
                              color: "#fff",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {handleVendorGetAllState &&
                      handleVendorGetAllState?.result?.length > 0 ? (
                        handleVendorGetAllState?.result?.map((row) => {
                          return (
                            <TableRow hover tabIndex={-1} key={row._id}>
                              <TableCell>{row.first_name}</TableCell>
                              <TableCell>{row.last_name}</TableCell>
                              <TableCell>{row.email}</TableCell>
                              {/* {row.status === "A" ? (
                                  <TableCell>
                                    <ActiveButton>Active</ActiveButton>
                                  </TableCell>
                                ) : (
                                  <TableCell>
                                    <ActiveButton
                                      style={{ backgroundColor: "red" }}
                                    >
                                      InActive
                                    </ActiveButton>
                                  </TableCell>
                                )} */}
                              <TableCell>{row.commission}%</TableCell>
                              <TableCell>
                                <div>
                                  <>
                                    <Tooltip title="View" placement="bottom">
                                      <Iconify
                                        icon={"eva:eye-fill"}
                                        sx={{ mr: 2 }}
                                        onClick={() => {
                                          handleNavigate(row);
                                        }}
                                      />
                                    </Tooltip>
                                    <Tooltip title="Edit" placement="bottom">
                                      <Iconify
                                        icon={"eva:edit-fill"}
                                        sx={{ mr: 2 }}
                                        onClick={() => {
                                          dispatch(
                                            VendorDetailsAction(row._id)
                                          );
                                          setVendorData(row);
                                          handleOpenEditModal();
                                        }}
                                      />
                                    </Tooltip>
                                    <Tooltip title="Delete" placement="bottom">
                                      <Iconify
                                        icon={"eva:trash-2-outline"}
                                        sx={{ mr: 2 }}
                                        onClick={() => {
                                          setVendorData(row);
                                          handleOpenModal();
                                        }}
                                      />
                                    </Tooltip>
                                  </>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={columns.length} align="center">
                            No Vendors Available.
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {isOpen && (
                  <VendorModal
                    handleClose={handleClose}
                    isOpen={isOpen}
                    page={page}
                    search={search}
                    handleVendorGetAll={handleVendorGetAll}
                  />
                )}

                {editVendorModal && (
                  <VendorModal
                    handleClose={handleCloseEditModal}
                    isVendor
                    data={vendorData}
                    page={page}
                    isOpen={editVendorModal}
                    search={search}
                    handleVendorGetAll={handleVendorGetAll}
                  />
                )}

                <RemoveModal
                  removeModal={removeModal}
                  handleCloseModal={handleCloseModal}
                  handelDeleteItem={() => handelDeleteItem(vendorData._id)}
                />
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={handleVendorGetAllState?.meta?.totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </Paper>
            </Card>
          </div>
        </Container>
      )}
      <Snackbar
        open={showAlert}
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
      >
        <Alert severity="success" onClose={() => setShowAlert(false)}>
          Vendor deleted successfully.
        </Alert>
      </Snackbar>
    </>
  );
}
