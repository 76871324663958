import * as React from "react";
import { useState, useEffect } from "react";
import {
  Container,
  Card,
  Button,
  Tooltip,
  Alert,
  Stack,
  Switch,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Chip,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import ComponentTitle from "../../components/common_Component/pageTitle/ComponentTitle";
import { useStyles } from "./productStyle.js";
import UserListToolbar from "../../sections/@dashboard/user/UserListToolbar";
import styled from "@emotion/styled";
import Iconify from "../../components/iconify";
import RemoveModal from "../../components/common_Component/RemoveModal";
import ProductModal from "../../components/common_Component/ProductModal";
import { GetAllProductAction } from "../../redux/action/GetProductAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DeleteProductAction } from "../../redux/action/DeleteProductAction";
// import { imageURL } from "../../utils/constant";
import Snackbar from "@mui/material/Snackbar";
import { GetAllProductVendorAction } from "../../redux/action/GetProductVendorAction.js";
import { useAuthContext } from "../../layouts/dashboard/AuthContext.js";
import { ProductRemoveApproval } from "../../redux/action/ProductRemoveApproval.js";
import { toast } from "react-toastify";
import moment from "moment";
import ApproveProduct from "./ApproveProduct.jsx";
import { CircularProgress } from "../../components/common_Component/CommonLoader.js";
const columns = [
  {
    id: "Image",
    label: "Image",
    minWidth: 70,
  },
  {
    id: "Product",
    label: "Product",
    minWidth: 80,
  },
  // { id: "Category", label: "Category", minWidth: 80 },
  {
    id: "markup_price",
    label: "Markup Price",
    minWidth: 80,
  },
  {
    id: "Price",
    label: "Vendor Price",
    minWidth: 80,
  },
  {
    id: "totalPrice",
    label: "Total Price",
    minWidth: 80,
  },
  {
    id: "VendorName",
    label: "Vendor Name",
    minWidth: 80,
  },
  {
    id: "lastModified",
    label: "Last Modified",
    minWidth: 100,
  },
  {
    id: "approve",
    label: "Approve",
    minWidth: 80,
  },
  {
    id: "Action",
    label: "Action",
    minWidth: 100,
  },
];

const AddButton = styled(Button)({
  background: "#a98f57",
  color: "#f1f3fa",
  width: "150px",
  height: "45px",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ProductPage() {
  const imageURL = process.env.REACT_APP_IMAGE_URL;
  const [page, setPage] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const [productData, setProductData] = React.useState({});
  const [removeModal, setRemoveModal] = React.useState(false);
  const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);
  const [productModal, setProductModal] = React.useState(false);
  const [editProductModal, setEditProductModal] = React.useState(false);
  const [approveFetching, setApproveFetching] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState("");
  const { isAdmin, isVendor } = useAuthContext();
  const userRole = isVendor ? "vendor" : isAdmin ? "admin" : "";

  const handleOpenModal = () => setRemoveModal(true);
  const handleCloseModal = () => setRemoveModal(false);
  const handleOpenProductModal = () => setProductModal(true);
  const handleCloseProductModal = () => setProductModal(false);
  const handleEditProductOpenModal = () => setEditProductModal(true);
  const handleEditProductCloseModal = () => setEditProductModal(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [approveProductModal, setApproveProductModal] = React.useState(false);
  const handleApproveProductModal = () =>
    setApproveProductModal(!approveProductModal);

  const GetAllProduct = (search, selectedProduct, pageNumber) => {
    dispatch(GetAllProductAction(search, selectedProduct, pageNumber + 1));
  };

  const GetAllProductVendor = (search, selectedProduct, pageNumber) =>
    dispatch(
      GetAllProductVendorAction(search, selectedProduct, pageNumber + 1)
    );

  const getAllProductState = useSelector(
    (state) => state.getAllProduct.productGetAllData
  );
  const fetching = useSelector((state) => state.getAllProduct.fetching);

  const getAllProductVendorState = useSelector(
    (state) => state.getAllProductVendor.productVendorGetAllData
  );

  const fetchingVendor = useSelector(
    (state) => state.getAllProductVendor.fetching
  );

  // Get Product
  useEffect(() => {
    if (userRole === "vendor") {
      GetAllProductVendor(search, selectedProduct, page);
    } else {
      GetAllProduct(search, selectedProduct, page);
    }
  }, [userRole, page, search, selectedProduct]);

  // Set pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Navigate on Product Details Page
  const handleNavigate = (data) => {
    navigate("/productDetails", { state: data });
  };

  const classes = useStyles();

  // Delete Product
  const handelDeleteItem = async () => {
    try {
      await DeleteProductAction(productData._id);
      setIsDeleteSuccess(true);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      handleCloseModal();
      if (userRole === "vendor") {
        GetAllProductVendor(search, selectedProduct, page);
      } else {
        GetAllProduct(search, selectedProduct, page);
      }
    }
  };

  // Admin Action
  const renderActionButton = (row) => {
    return (
      <>
        <Tooltip title="Edit" placement="bottom">
          <Iconify
            icon={"eva:edit-fill"}
            sx={{ mr: 2 }}
            onClick={(e) => {
              e.stopPropagation();
              setProductData(row);
              handleEditProductOpenModal();
            }}
          />
        </Tooltip>
        <Tooltip title="Delete" placement="bottom">
          <Iconify
            icon={"eva:trash-2-outline"}
            sx={{ mr: 2 }}
            onClick={(e) => {
              e.stopPropagation();
              setProductData(row);
              handleOpenModal();
            }}
          />
        </Tooltip>
      </>
    );
  };

  // Product Approval
  const handleChange = async (productID) => {
    const productId = { product_id: productID };
    try {
      const res = await ProductRemoveApproval(productId);
      if (res?.isSuccess) {
        setApproveFetching(false);
        GetAllProduct(search, selectedProduct, page);
      }
    } catch (error) {
      setApproveFetching(false);
      toast.error(error.message);
    }
  };

  const productShort = [
    {
      label: "A to Z",
      value: "atoz",
    },
    {
      label: "Price - low to high",
      value: "priceLow",
    },
    {
      label: "Price - high to low",
      value: "priceHigh",
    },
  ];

  return (
    <>
      <Container className={classes.container}>
        <ComponentTitle pageTitle="Products" />
        <div className={classes.mainDiv}>
          <div className={classes.displaySpace} style={{ paddingLeft: "0px" }}>
            <UserListToolbar
              search={search}
              handleSearch={(e) => {
                setSearch(e.target.value);
                setPage(0);
              }}
              style={{ paddingLeft: "0px" }}
              name="Product"
            />
            <div
              style={{
                display: "flex",
                gap: "10px",
              }}
            >
              <FormControl sx={{ width: 200, height: 40 }}>
                <InputLabel id="filters" style={{ marginTop: "-5px" }}>
                  Filters
                </InputLabel>
                <Select
                  fullWidth
                  labelId="filters"
                  id="filters"
                  value={selectedProduct}
                  onChange={(event) => setSelectedProduct(event.target.value)}
                  autoWidth
                  label="Filters"
                >
                  {productShort?.map((product) => (
                    <MenuItem key={product.label} value={product.value}>
                      {product.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {isAdmin ? null : (
                <AddButton
                  variant="text"
                  onClick={() => {
                    handleOpenProductModal();
                  }}
                >
                  + Add Product
                </AddButton>
              )}
            </div>
          </div>
          <Card
            sx={{
              marginBottom: 2,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              {fetching || fetchingVendor || approveFetching ? (
                <CircularProgress />
              ) : (
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns?.map((column) =>
                          column?.id === "VendorName" && !isAdmin ? null : (
                            <>
                              {column?.id === "lastModified" &&
                              !isAdmin ? null : (
                                <>
                                  {column?.id === "markup_price" &&
                                  !isAdmin ? null : (
                                    <>
                                      {column?.id === "totalPrice" &&
                                      !isAdmin ? null : (
                                        <TableCell
                                          key={column?.id}
                                          align={column?.align}
                                          style={{
                                            minWidth: column?.minWidth,
                                            backgroundColor: "#a98f57",
                                            color: "#fff",
                                          }}
                                        >
                                          {column?.label}
                                        </TableCell>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userRole === "vendor" ? (
                        getAllProductVendorState &&
                        getAllProductVendorState?.result?.length > 0 ? (
                          getAllProductVendorState?.result?.map(
                            (row, index) => {
                              let imgUrl = `${imageURL + row?.image[0]}`;
                              return (
                                <TableRow hover tabIndex={-1} key={row._id}>
                                  <TableCell>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <img
                                        src={imgUrl}
                                        crossOrigin="anonymous"
                                        style={{
                                          height: "50px",
                                          width: "50px",
                                          objectFit: "contain",
                                        }}
                                        onError={(e) => {
                                          e.target.style.display = "none";
                                        }}
                                      />
                                    </Stack>
                                  </TableCell>
                                  <TableCell>{row.title}</TableCell>
                                  {/* <TableCell>{row?.category?.name}</TableCell> */}
                                  <TableCell>${row.price.toFixed(2)}</TableCell>
                                  <TableCell>
                                    {row.status === "P" ? (
                                      <Chip
                                        variant="outlined"
                                        label="Pending"
                                        color="warning"
                                      />
                                    ) : (
                                      <Chip
                                        variant="outlined"
                                        label="Approved"
                                        color="success"
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <div>
                                      <>
                                        <Tooltip
                                          title="View"
                                          placement="bottom"
                                        >
                                          <Iconify
                                            icon={"eva:eye-fill"}
                                            sx={{ mr: 2 }}
                                            onClick={() => {
                                              handleNavigate(row);
                                            }}
                                          />
                                        </Tooltip>
                                        <Tooltip
                                          title="Edit"
                                          placement="bottom"
                                        >
                                          <Iconify
                                            icon={"eva:edit-fill"}
                                            sx={{ mr: 2 }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setProductData(row);
                                              handleEditProductOpenModal();
                                            }}
                                          />
                                        </Tooltip>
                                        <Tooltip
                                          title="Delete"
                                          placement="bottom"
                                        >
                                          <Iconify
                                            icon={"eva:trash-2-outline"}
                                            sx={{ mr: 2 }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setProductData(row);
                                              handleOpenModal();
                                            }}
                                          />
                                        </Tooltip>
                                      </>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )
                        ) : (
                          <TableRow>
                            <TableCell colSpan={columns.length} align="center">
                              No data available.
                            </TableCell>
                          </TableRow>
                        )
                      ) : getAllProductState &&
                        getAllProductState?.result?.length > 0 ? (
                        getAllProductState?.result?.map((row, index) => {
                          let imgUrl = `${imageURL + row?.image[0]}`;
                          return (
                            <TableRow hover tabIndex={-1} key={row._id}>
                              <TableCell>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <img
                                    src={imgUrl}
                                    crossOrigin="anonymous"
                                    style={{
                                      height: "50px",
                                      width: "50px",
                                    }}
                                    onError={(e) => {
                                      e.target.style.display = "none";
                                    }}
                                  />
                                </Stack>
                              </TableCell>
                              <TableCell>{row?.title}</TableCell>
                              {/* <TableCell>{row?.category?.name}</TableCell> */}
                              <TableCell>
                                {row?.markup_type === "Flat" ? "$" : ""}
                                {row?.markup_price
                                  ? Number(row?.markup_price).toFixed(2)
                                  : "-"}
                                {row?.markup_type === "Percentage" ? "%" : ""}
                              </TableCell>
                              <TableCell>
                                $
                                {row?.price ? Number(row?.price).toFixed(2) : 0}
                              </TableCell>
                              <TableCell>
                                $
                                {row?.finalPrice
                                  ? Number(row?.finalPrice).toFixed(2)
                                  : 0}
                              </TableCell>
                              {columns.some(
                                (column) => column?.id === "VendorName"
                              ) && !isAdmin ? null : (
                                <TableCell>
                                  {row?.vendorDetails?.first_name} {""}
                                </TableCell>
                              )}
                              <TableCell>
                                {moment(
                                  row?.updatedAt ?? row?.createdAt
                                ).format("DD-MM-YYYY")}
                              </TableCell>
                              <TableCell>
                                <Switch
                                  checked={!(row.status === "P")}
                                  onChange={() => {
                                    if (!(row.status === "P")) {
                                      setApproveFetching(true);
                                      handleChange(row?._id);
                                    } else {
                                      handleApproveProductModal();
                                      setProductData(row);
                                    }
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <div>
                                  <>
                                    <Tooltip title="View" placement="bottom">
                                      <Iconify
                                        icon={"eva:eye-fill"}
                                        sx={{ mr: 2 }}
                                        onClick={() => {
                                          handleNavigate(row);
                                        }}
                                      />
                                    </Tooltip>
                                    {renderActionButton(row)}
                                  </>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={columns.length} align="center">
                            No data available.
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              <RemoveModal
                removeModal={removeModal}
                handleCloseModal={handleCloseModal}
                handelDeleteItem={() => handelDeleteItem()}
              />
              {productModal && (
                <ProductModal
                  productModal={productModal}
                  page={page}
                  handleCloseModal={handleCloseProductModal}
                  selectedProduct={selectedProduct}
                />
              )}
              {editProductModal && (
                <ProductModal
                  isUser
                  page={page}
                  data={productData}
                  productModal={editProductModal}
                  handleCloseModal={handleEditProductCloseModal}
                  selectedProduct={selectedProduct}
                />
              )}

              <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={
                  userRole === "admin"
                    ? getAllProductState?.meta?.totalCount
                    : getAllProductVendorState?.meta?.totalCount
                }
                rowsPerPage={10}
                page={page}
                onPageChange={handleChangePage}
                labelDisplayedRows={({ from, to, count }) =>
                  !count ? "0-0 of 0" : `${from}-${to} of ${count}`
                }
              />
            </Paper>
          </Card>
        </div>
        <Snackbar
          open={isDeleteSuccess}
          autoHideDuration={3000}
          onClose={() => setIsDeleteSuccess(false)}
        >
          <Alert severity="success" onClose={() => setIsDeleteSuccess(false)}>
            Product deleted successfully!
          </Alert>
        </Snackbar>
        {approveProductModal && (
          <ApproveProduct
            productData={productData}
            GetAllProduct={GetAllProduct}
            search={search}
            page={page}
            style={style}
            setApproveProductModal={setApproveProductModal}
            selectedProduct={selectedProduct}
            approveProductModal={approveProductModal}
            handleApproveProductModal={handleApproveProductModal}
            setApproveFetching={setApproveFetching}
            setProductData={setProductData}
          />
        )}
      </Container>
    </>
  );
}
