import React, { useEffect, useState } from "react";
import { useStyles } from "./dashboardStyle";
import ComponentTitle from "../../components/common_Component/pageTitle/ComponentTitle.js";
import {
  Box,
  Card,
  CardContent,
  Container,
  Typography,
  Grid,
} from "@mui/material";
import { GetDashboardAdminAction } from "../../redux/action/GetDashboardAdminAction.js";
import { useDispatch } from "react-redux";
import { useAuthContext } from "../../layouts/dashboard/AuthContext.js";
import { GetDashboardVendorAction } from "../../redux/action/GetDashboardVendorAction.js";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "../../components/common_Component/CommonLoader.js";

export default function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [adminData, setAdminData] = useState({});
  const [vendorData, setVendorData] = useState({});
  const [loading, setLoading] = useState(true);
  const { isAdmin, isVendor } = useAuthContext();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);

    const fetchAdminData = async () => {
      try {
        const result = await GetDashboardAdminAction(dispatch);
        setAdminData(result.data);
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    };
    const fetchVendorData = async () => {
      try {
        const result = await GetDashboardVendorAction(dispatch);
        setVendorData(result.data);
      } catch (error) {
        console.error("Error fetching vendor data:", error);
      }
    };
    if (isAdmin) {
      fetchAdminData();
    } else {
      fetchVendorData();
    }
  }, []);

  const classes = useStyles();

  return (
    <Container className={classes.container} maxWidth="lg">
      <ComponentTitle pageTitle="Dashboard" />
      <>
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box className={classes.table}>
                {isAdmin && (
                  <>
                    <Card className={classes.card}>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          navigate("/users");
                        }}
                      >
                        <CardContent>
                          <Typography className={classes.title} gutterBottom>
                            Customers
                          </Typography>
                          <Typography variant="h6" className={classes.price}>
                            {adminData?.user || 0}
                          </Typography>
                          <Typography className={classes.pr}>
                            Total Customers
                          </Typography>
                        </CardContent>
                      </div>
                    </Card>
                    <Card className={classes.card}>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          navigate("/orders");
                        }}
                      >
                        <CardContent>
                          <Typography className={classes.title} gutterBottom>
                            Orders
                          </Typography>
                          <Typography variant="h6" className={classes.price}>
                            {adminData?.order || 0}
                          </Typography>
                          <Typography className={classes.pr}>
                            Total Orders
                          </Typography>
                        </CardContent>
                      </div>
                    </Card>
                    <Card className={classes.card}>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          navigate("/product");
                        }}
                      >
                        <CardContent>
                          <Typography className={classes.title} gutterBottom>
                            Products
                          </Typography>
                          <Typography variant="h6" className={classes.price}>
                            {adminData?.product || 0}
                          </Typography>
                          <Typography className={classes.pr}>
                            Total Products
                          </Typography>
                        </CardContent>
                      </div>
                    </Card>
                    <Card className={classes.card}>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          navigate("/vendor");
                        }}
                      >
                        <CardContent>
                          <Typography className={classes.title} gutterBottom>
                            Vendors
                          </Typography>
                          <Typography variant="h6" className={classes.price}>
                            {adminData?.vendor || 0}
                          </Typography>
                          <Typography className={classes.pr}>
                            Total Vendors
                          </Typography>
                        </CardContent>
                      </div>
                    </Card>
                  </>
                )}
                {isVendor && (
                  <>
                    <Card className={classes.card}>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          navigate("/product");
                        }}
                      >
                        <CardContent>
                          <Typography className={classes.title} gutterBottom>
                            Products
                          </Typography>
                          <Typography variant="h6" className={classes.price}>
                            {vendorData?.product || 0}
                          </Typography>
                          <Typography className={classes.pr}>
                            Total Products
                          </Typography>
                        </CardContent>
                      </div>
                    </Card>
                    <Card className={classes.card}>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          navigate("/orders");
                        }}
                      >
                        <CardContent>
                          <Typography className={classes.title} gutterBottom>
                            Orders
                          </Typography>
                          <Typography variant="h6" className={classes.price}>
                            {vendorData?.order || 0}
                          </Typography>
                          <Typography className={classes.pr}>
                            Total Orders
                          </Typography>
                        </CardContent>
                      </div>
                    </Card>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        )}
      </>
    </Container>
  );
}
